import { Card, Col, Row } from "react-bootstrap";
import { AppDocumentsSection} from "../../resources/application";
import { FileButton } from "../common/FileButton";

interface Props {
    documents: AppDocumentsSection[];
}


export const AppDocs: React.FC<Props> = (props) => {    
    return (
        <Row as={Col}>
            {props.documents.map((sec, i) => (
                <Col key={`doc_col_${i}`} sm="12" lg="12">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h4>{sec.section.toUpperCase()}</h4>
                                </Col>
                            </Row>
                            <Row>
                                {sec.documents && sec.documents.map((dok, i) =>
                                (
                                    <Col key={`dokuments_${i}`} md="4"><FileButton fileId={dok.id.toString()} fileName={dok.name} fileSize={dok.size} fileDate={dok.date} mime={dok.contentType} /></Col>
                                )
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}