import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
	mobileAsideToggle: () => void
	mobileToolbarToggle: () => void
}
interface State {
	isMenuActive: boolean
}

class HeaderBaseMobile extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			isMenuActive: false
		}
	}

	render(): ReactNode {
		return (
			<div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
				<div className="kt-header-mobile__logo">
					<Link to="/">
						<img alt="Logo" src={`${process.env.PUBLIC_URL}/assets/picts/alis-public.svg`} />
					</Link>
				</div>
				<div className="kt-header-mobile__toolbar">
					<button onClick={this.props.mobileAsideToggle} className={`kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left ${ this.state.isMenuActive ? "kt-header-mobile__toolbar-toggler--active" : ''}`} id="kt_aside_mobile_toggler"><span></span></button>
					{/* <button className="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span></span></button> */}
					<button onClick={this.props.mobileToolbarToggle} className="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
				</div>
			</div>
		)
	}
}

export default HeaderBaseMobile
