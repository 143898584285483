import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ErrorContext } from "../ErrorContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AsmuoValues, BasicAuthReq, createSessionViisp, postBasicAuth, postViisp, postViispMock, toAuthorizationViisp } from "../../resources/auth";
import { Formik, useField } from "formik";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { LkmpFormField } from "../form/LkmpFormField";
import { AuthContext, Authorization } from "../AuthContext";
import { values } from "ramda";

export interface ViispMockAuthRequest {
    mockAuthData: ViispAuthData;
    parameters:{
        [key: string]: string;
    }
}

export interface ViispAuthData {
    personCode:string;
    firstName?:string;
    lastName?:string;
    companyCode?:string;
    companyName?:string;
    governmentEmployeeCode?:string;
    exists?:boolean;
    blocked?:boolean;
    customData?:{
        [key: string]: string;
    };
    origin?:string;
    ticket?:string;
} 

export interface ViispAuthResult{
    viispUrlWithTicket: string;
}


export const ViispMockAuth: React.FC = () => {
    const { setError } = useContext(ErrorContext);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [urlTicket, setUrlTicket] = useState<string | undefined>(undefined); 
    interface FormikViispMockAuthRequest  extends  ViispMockAuthRequest {
        org:string;
    } 
    const initVal: FormikViispMockAuthRequest ={
        mockAuthData: {
            personCode: "",
            firstName:"",
            lastName:"",
            origin: process.env.REACT_APP_VIISP_AUTH_APP_SUCCESS_URL || "http://localhost:3000",
            companyName:"Valstybinė saugomų teritorijų tarnyba",
            companyCode:"188724381",            
        },
        parameters: {
            "AUTH_TYPE":"VIISP_AUTH",
            "USER_TYPE":"EXTERNAL"
        },
        org: "STVK",
        
    };
    const doPost = useCallback(
        (values: ViispMockAuthRequest) => {
            postViispMock(values)
                .then((values) => {
                    process.env.REACT_APP_API_DOMAIN_URL && setUrlTicket(process.env.REACT_APP_API_DOMAIN_URL + values.viispUrlWithTicket);                    
                    console.log(values);                    
                })
                .catch(setError);
        },
        [setError]
    );

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
       if (urlTicket && formRef.current) {
            console.log("submiting form to VIISP MOCK service --->");
			formRef.current.submit();
		}
	}, [urlTicket]);

	if (urlTicket) {
		return (
			<form ref={formRef} name="REQUEST" action={urlTicket} method="post">
				<input type="hidden" name="url" value={urlTicket}/>
			</form>
		);
	}

    return (
        <>
            <div>
                <Formik
                    initialValues={initVal}
                    onSubmit={(values,  formikHelpers) => { 
                        console.log(values);                        
                        doPost(values);
                        formikHelpers.setSubmitting(false);
                    }}
                >
                    {({ handleSubmit, isSubmitting, setFieldValue, values  }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit} className="pt-3">
                                <Form.Label className="mt-2">VIISP MOCK prisijungimas:</Form.Label>
                                <Card>
                                    <Card.Header style={{ fontWeight: "bold", color: "darkorange" }}>VARTOTOJAS</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Asmens kodas</Form.Label>
                                                <LkmpFormField name="mockAuthData.personCode">
                                                    <Form.Control type="text" />
                                                </LkmpFormField>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Vardas</Form.Label>
                                                <LkmpFormField name="mockAuthData.firstName">
                                                    <Form.Control type="text" />
                                                </LkmpFormField>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Pavarde</Form.Label>
                                                <LkmpFormField name="mockAuthData.lastName">
                                                    <Form.Control type="text" />
                                                </LkmpFormField>
                                            </Col>
                                        </Row>  
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Organization</Form.Label>
                                                <Form.Check 
                                                    type={"radio"}
                                                    name="org"
                                                    id={`STVK`}
                                                    label={`VSTT`}
                                                    checked = {values.org === "STVK"} 
                                                    onChange={() => {setFieldValue("org", "STVK"); setFieldValue("mockAuthData.companyName", "Valstybinė saugomų teritorijų tarnyba"); setFieldValue("mockAuthData.companyCode", "188724381")}}
                                                />
                                                <Form.Check 
                                                    type={"radio"}
                                                    name="org"
                                                    id={`STVK_188715560`}
                                                    label={`Aukštaitijos nacionalinio parko ir Labanoro regioninio parko direkcija`}
                                                    checked = {values.org === "STVK_188715560"} 
                                                    onChange={() => {setFieldValue("org", "STVK_188715560"); setFieldValue("mockAuthData.companyName", "Aukštaitijos nacionalinio parko ir Labanoro regioninio parko direkcija"); setFieldValue("mockAuthData.companyCode", "188715560")}}
                                                />
                                                <Form.Check 
                                                    type={"radio"}
                                                    name="org"
                                                    id={`STVK_306108968`}
                                                    label={`Aukštaitijos saugomų teritorijų direkcija`}
                                                    checked = {values.org === "STVK_306108968"} 
                                                    onChange={() => {setFieldValue("org", "STVK_306108968"); setFieldValue("mockAuthData.companyName", "Aukštaitijos saugomų teritorijų direkcija"); setFieldValue("mockAuthData.companyCode", "306108968")}}
                                                />
                                                <Form.Check 
                                                    type={"radio"}
                                                    name="org"
                                                    id={`KVR`}
                                                    label={`KPD`}
                                                    onChange={() => {setFieldValue("org", "KPD");  setFieldValue("mockAuthData.companyName", "Kultūros paveldo departamentas prie Kultūros ministerijos"); setFieldValue("mockAuthData.companyCode", "188692688")}}
                                                    checked = {values.org === "KPD"}                
                                                />
                                                <Form.Check 
                                                    type={"radio"}
                                                    name="org"
                                                    id={`FAIL`}
                                                    label={`FAIL`}
                                                    onChange={() => {setFieldValue("org", "FAIL");  setFieldValue("mockAuthData.companyName", "UAB 'Feilas'"); setFieldValue("mockAuthData.companyCode", "111111111")}}
                                                    checked = {values.org === "FAIL"}                
                                                />
                                                <input type="hidden" placeholder="Email" name="mockAuthData.companyName"/>
                                                <input type="hidden" placeholder="Email" name="mockAuthData.companyCode" />
                                            </Col>
                                        </Row>                                    
                                    </Card.Body>
                                </Card>
                                <Row>
                                    <Col md="12">
                                        <Button variant="outline-success" style={{ fontWeight: "bold" }} disabled={isSubmitting} type="submit">
                                            PRISIJUNGTI (TESTAS)
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};