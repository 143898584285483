import React, {useCallback, useContext, useEffect, useState} from "react"
import "./FileButton.css"
import { Card } from "react-bootstrap"
import { getEvaluationAppDocumentByDocId } from "../../resources/application";
import { downloadFile, downloadFileByUrl } from "../../resources/download";
import { ErrorContext } from "../ErrorContext";



interface Props {
    fileName: string;
    fileSize?: number;
    fileDate?: string;
    mime?: string;
    fileId: string;
}


export const FileButton: React.FC<Props> = (props) => {
    const {setError} = useContext(ErrorContext);

    function bytesToStr(bytes?:number):string {
        if(bytes){
            if(bytes && bytes > (1024 * 1024)){
                return (bytes / (1024 * 1024)).toFixed(2) + " MB"
            } else if(bytes && bytes > 1024) {
                return (bytes /  1024).toFixed(2) + " KB"
            } else {
                return bytes + " B"
            }
        }
        return " ";
    }

    function resolveFileType(mime?:string): any{
        if(props.mime == "application/pdf"){
            return "pdf";
        }

        if(props.mime == "application/msword" || props.mime == "application/msword" || props.mime == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
            return "doc";
        }
        if(props.mime == "application/vnd.ms-excel" || props.mime == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
            return "xls";
        }
        if(props.mime == "application/zip" || props.mime == "application/vnd.rar"  || 	props.mime == "application/x-7z-compressed"){
            return "zip";
        }
        if(props.mime == "application/geo+json"){
            return "geojson";
        }        
        return undefined;
    }

    const downFile = useCallback((fileUrl: string, fileName?: string) => {
		downloadFile(fileUrl, fileName)
			.catch(setError);
	}, [setError]);

    function getFile(docId:string): void {
        getEvaluationAppDocumentByDocId(docId).then(f => {
           downFile(`common/get-file/${f.fil_key}`, f.fil_name);  
        });
    }
 

    return (<>
    {console.log("props mime --> ", props.mime) }
        <Card style={{marginBottom: "5px"}}>
            <Card.Body style={{padding: "0px"}}>
                <button className="fb_button" onClick={() => {getFile(props.fileId)
                }}>
                    {
                        resolveFileType(props.mime) == "pdf" &&
                        (<div className="fb_icon fb_icon_pdf">                            
                            <i className="fas fa-file-pdf fa-lg" style={{ color: 'red' }}></i>
                        </div>)                                          
                    }
                    {
                        resolveFileType(props.mime) == "doc" &&
                        (<div className="fb_icon fb_icon_doc">                            
                            <i className="fas fa-file-word fa-lg" style={{ color: 'rgb(2, 7, 248)' }}></i>
                        </div>)                                          
                    }
                    {
                        resolveFileType(props.mime) == "xls" &&
                        (<div className="fb_icon fb_icon_xls">                            
                            <i className="fas fa-file-excel fa-lg" style={{ color: 'rgb(0, 170, 91)' }}></i>
                        </div>)                                          
                    }
                    {
                        resolveFileType(props.mime) == "zip" &&
                        (<div className="fb_icon fb_icon_zip">                            
                            <i className="fas fa-file-archive fa-lg" style={{ color: 'rgb(112, 66, 5)' }}></i>
                        </div>)                                          
                    }
                    {
                        resolveFileType(props.mime) == "geojson" &&
                        (<div className="fb_icon fb_icon_geojson">                            
                            <i className="fas fa-file-code fa-lg" style={{ color: 'rgb(120, 7, 165)' }}></i>
                        </div>)                                          
                    }
                    {
                        !resolveFileType(props.mime) &&
                        (<div className="fb_icons">                            
                            <i className="fas fa-file fa-lg"></i>
                        </div>)                                          
                    }
                
                    
                    <div className="fb_info">
                        <strong>{props.fileName}</strong>
                        <span>{props.fileDate + ", " + bytesToStr(props.fileSize)}</span>
                    </div>
                </button>
            </Card.Body>
        </Card>
    </>
        

    )

}