import React, { ReactNode } from 'react'
import ContentPage from './ContentPage'
import { IPageType } from '../../../../permitRoutes';

interface Props {
  title: string
  icon?: string
  category?: string
  subcategory?: string
  fluidWidth?: boolean
  headerButtons?: JSX.Element
  pageType?: IPageType
  hideWhiteBackground?: boolean
  children?: ReactNode
}

class ContentBase extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render(): ReactNode {
    return (
      <div className={`kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-0${ this.props.pageType ? ' ' + this.props.pageType : ''}`}>
        {
          this.props.pageType !== 'strapi-page' && this.props.title && // this.props.headerButtons &&
          <div className="content-subheader kt-subheader kt-grid__item py-2" id="kt_subheader">
            <div className={`kt-container${this.props.fluidWidth ? ' kt-container--fluid' : ''}`}>
              <div className="kt-subheader__main">
                <h3 className="kt-subheader__title">
                  {
                    this.props.title && this.props.icon &&
                    <i className={this.props.icon + ' mr-3'} />
                  }
                  {this.props.title}
                </h3>
              </div>
              <div className="kt-subheader__toolbar">
                &nbsp;{this.props.headerButtons}
              </div>
            </div>
          </div>
        }
        <ContentPage children={this.props.children} fluidWidth={this.props.fluidWidth} hideWhiteBackground={this.props.hideWhiteBackground} />
      </div>
    )
  }
}
export default ContentBase;
