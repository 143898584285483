import { apiGet } from "./api";
import * as R from "ramda";

export interface Classifier {
    id: number;
    key: string;
    display: string;
}

export function getMunicipalitie(): Promise<Classifier[]> {
    return apiGet(`/lkmp/ext/get-municipalities`, {xApiKey:"pgQcVT&b18PRSC16#c4IzFXL0CjbvAQX"});
}

