import { TFunction } from 'i18next';
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

interface Props {
  menu: { title: string, titleEn: string, url:string }[]
  locale: string;
  t: TFunction;
}

class TopbarMenuPC extends React.Component<Props> {
  render(): ReactNode {
    return (
      <></>
    )
  }
}

export const TopbarMenu: React.FC<{menu: { title: string, titleEn: string, url: string }[]}> = ({menu}) => {

	const { t, i18n } = useTranslation();
  
	return (
		  <TopbarMenuPC menu={menu} locale={i18n.language} t={t} />
	);
}

export default TopbarMenu
