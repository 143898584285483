import React from "react";
import {FieldArray, useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import ReactDatePicker, {ReactDatePickerProps} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import {LkmpFormFieldPastaba, fieldValid} from "./LkmpFormField";

const LkmpFormDateFieldInput: React.FC<{name: string; pastaba?: string} & any> = React.forwardRef(({name, pastaba, ...props}, ref) => {
	const [, meta] = useField<Date>(name);
	const {submitCount} = useFormikContext<any>();

	return (
		<>
			<Form.Control
				ref={ref}
				{...props}
				{...fieldValid(meta, submitCount)}
			/>
			<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
			<LkmpFormFieldPastaba pastaba={pastaba} />
		</>
	);
});

interface LkmpFormDateFieldProps {
	name: string;
	pastaba?: string;
	onChange?(date: Date | null, event: React.SyntheticEvent<any> | undefined): void;
	additionalProps?: Partial<ReactDatePickerProps>;
}

export const LkmpFormDateField: React.FC<LkmpFormDateFieldProps> = ({name, pastaba, onChange, additionalProps = {}}) => {
	const [field] = useField<Date>(name);
	const {setFieldValue} = useFormikContext<any>();
	const {t, i18n} = useTranslation();

	return (
		<ReactDatePicker
			wrapperClassName="w-100"
			locale={i18n.language}
			selected={field.value}
			dateFormat={t('common.dateFormat')}
			name={field.name}
			onChange={(date, event) => {
				setFieldValue(name, date, true);
				if (onChange) {
					onChange(date, event);
				}
			}}
			onBlur={field.onBlur}
			isClearable={true}
			customInput={<LkmpFormDateFieldInput name={name} pastaba={pastaba} />}

			{...additionalProps}
		/>
	);
};

export const LkmpFormMultiDateField: React.FC<{ name: string; pastaba?: string }> = ({name, pastaba}) => {
	const [field] = useField<Date[]>(name);

	const valuesCount = field.value ? field.value.length : 0;

	return (
		<FieldArray
			name={name}
			render={arrayHelpers => (
				<>
					{valuesCount > 0 && field.value.map((date, index) => (
						<div key={index}>
							<LkmpFormDateField
								name={`${name}[${index}]`}
								onChange={date => {
									if (date == null) {
										arrayHelpers.remove(index);
									}
								}}
							/>
						</div>
					))}
					<div key={valuesCount}>
						<LkmpFormDateField name={`${name}[${valuesCount}]`} />
					</div>
					<LkmpFormFieldPastaba pastaba={pastaba} />
				</>
			)}
		/>
	);
};
