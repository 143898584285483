import React, {useCallback, useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import {readViispTicket} from "../../resources/auth";
import Alert from "react-bootstrap/Alert";
import {Trans} from "react-i18next";
import { ViispAuthRequest } from "./ViispAuth";
import { Col, Row } from "react-bootstrap";

export const ViispAuthenticationStart: React.FC = () => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [ticket, setTicket] = useState<string | null>(null);

	const login = useCallback(() => {
		setBusy(true);
		setError(null);
		const viispReqParams: ViispAuthRequest = {
			parameters: {

			}
		}
		readViispTicket(viispReqParams)
			.then(val => {
				console.log(val.viispUrlWithTicket);

				
				setTicket(val.viispUrlWithTicket);

			})
			.catch(() => {
				setError('prisijungti.error');
				setBusy(false);
			});

	}, []);

	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		if (ticket && formRef.current) {
			formRef.current.submit();
		}
	}, [ticket]);

	if (ticket) {
		return (
			<form ref={formRef} name="REQUEST" action={ticket} method="post">
				{/* <input type="hidden" name="ticket" value={ticket}/> */}
			</form>
		);
	}

	return (
		<div style={{textAlign: "center"}}>
			<Button disabled={busy} variant="link" onClick={login}>
				<Row>
					<Col sm="1"><img src="/assets/picts/eValdzia-logo.png" alt="image" style={{width: "100%"}} /></Col>
					<Col sm="6">{busy ? 
					<h2><Trans i18nKey="prisijungti.jungiamasi" /></h2> : <h2><Trans i18nKey="prisijungti.viisp" /></h2>}</Col>
				</Row>
						
				
			</Button>
			{error && <Alert variant="danger"><Trans i18nKey={error}/></Alert>}
		</div>
	);
};
