import { ReactNode, useContext } from 'react'
import { IRouteMeta } from '../../permitRoutes';
import { AuthContext } from '../AuthContext'
import PageLayout from './PageLayout'
import AdminLayout from './AdminLayout'
import UpdateSiteDialog from '../../components/UpdateSiteDialog';
import {  useLoaderData, useParams } from 'react-router-dom';
import { LayoutProps } from '../App';
import { Login } from '../Login';


interface Props {
  route?: IRouteMeta
  layout?: string
  children?: ReactNode
  layoutProps?: LayoutProps

}

interface State { }

const DefaultLayout = (props: Props) => {

  const params = useParams();
  const auth = useContext(AuthContext);
  const data: any = useLoaderData();


  return (
    <>    
      {
        (
          // auth.authenticated && 
          // (!!!auth.organization?.contactInfo?.email 
          //   || !!!auth.organization?.contactInfo?.phone 
          //   || !!!auth.organization?.contactInfo?.address 
          //   ) &&
          //   <PageLayout>
              
          //   <h1>{auth.organization?.contactInfo?.email} </h1>                              
          // </PageLayout>
          // || 


          auth.authenticated && (
            <AdminLayout>
              <>  
              {props.children}
              </>
              
            </AdminLayout>
          )
          ||
          (
            <PageLayout>
              <Login />           
            </PageLayout>
          )
        )
      }
      <UpdateSiteDialog />
    </>
  );
};

export default DefaultLayout
