import { apiGet, ApiResponsePayloadExtractor, pdfGet, pdfGetV2, apiPost } from "./api";
import { saveAs } from "file-saver";
import { LoginResult, WebSessionInfo } from "./auth";

function download(apiCall: (payloadExtractor: ApiResponsePayloadExtractor) => Promise<any>): Promise<any> {
    return apiCall(async (response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        if (!contentDisposition) {
            throw Error("No content-disposition header");
        }

        let filename = contentDisposition.split("filename=")[1];
        // remove the quotation marks
        filename = filename.slice(1, filename.length - 1);

        const blob = await response.blob();
        saveAs(blob, filename);

        return filename;
    });
}

export function downloadParaiskaFile(id: string): Promise<any> {
    return download((payloadExtractor) => apiGet("/v1/paraiska/downloadFile", { uriPath: [id] }, payloadExtractor));
}

export function downloadLeidimas(id: number): Promise<any> {
    return Promise.reject();
}

function download2(apiCall: (payloadExtractor: ApiResponsePayloadExtractor) => Promise<any>, type: string, leidimoNr: string): Promise<any> {
    return apiCall(async (response) => {
        let filename = type + "_" + leidimoNr;

        const blob = await response.blob();
        saveAs(blob, filename);

        return filename;
    });
}

export function downloadPdfFile(type: string, id: number, leidimoNr: string): Promise<any> {
    return download2((payloadExtractor) => pdfGet(`/${type}/${id}?output=pdf`, payloadExtractor), type, leidimoNr);
}

export function downloadFileByUrl(fileUrl: string, filename?: string): Promise<any> {
    let urlArr = fileUrl.split("/");
    return genericDownload((payloadExtractor) => apiGet("/download", { uriPath: [...urlArr] }, payloadExtractor), fileUrl, filename);
}

export function downloadFile(fileUrl: string, filename?: string): Promise<any> {
    let urlArr = fileUrl.split("/");
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return genericDownload((payloadExtractor) => apiGet("", { uriPath: [...urlArr], userToken: { type: "Bearer", token: session.token } }, payloadExtractor), fileUrl, filename);
}

export function downloadFileByFilter(fileUrl: string, filterValues: any, filename?: string): Promise<any> {
    return genericDownload((payloadExtractor) => apiPost(fileUrl, {}, filterValues, "application/json", payloadExtractor), fileUrl, filename);
}

export function genericDownload(
    apiCall: (payloadExtractor: ApiResponsePayloadExtractor) => Promise<any>,
    fileUrl: string,
    filename?: string
): Promise<any> {
    return apiCall(async (response) => {
        let urlArr = fileUrl.split("/");
        let fileName = filename ? filename : urlArr[urlArr.length - 1];

        const blob = await response.blob();
        saveAs(blob, fileName);

        return fileName;
    });
}

// New downloader

function downloadPdf(apiCall: (payloadExtractor: ApiResponsePayloadExtractor) => Promise<any>, type: string, leidimoNr: string): Promise<any> {
    return apiCall(async (response) => {
        let filename = type + "_" + leidimoNr + ".pdf";

        const blob = await response.blob();
        saveAs(blob, filename);

        return filename;
    });
}

export function downloadLeidimasFromPublicByUrl(id: number, type: string, leidimoNr: string, permitUrl: string): Promise<any> {
    return downloadPdf((payloadExtractor) => pdfGetV2(`/public/v1/${permitUrl}/atsisiusti/${id}`, payloadExtractor), type, leidimoNr);
}

export function downloadLeidimasByUrl(id: number, type: string, leidimoNr: string, permitUrl: string): Promise<any> {
    return downloadPdf((payloadExtractor) => pdfGetV2(`/v1/leidimas/atsisiusti/${type}/${id}`, payloadExtractor), type, leidimoNr);
}
