import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import { ItemWithChildItems, RequestIssuedDoc, getApplicationIssuedDoc, getApplicationIssuedDocCls, getPadGir } from "../../resources/application";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faEnvelope, faLocationDot, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";


interface Props {
    data?: any;
}



interface LkmpClassifierDto {
    id: number,
    key: string;
    display: string;
}

export const AppIssued: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [data, setDate] = useState<RequestIssuedDoc>();
    const [dataCls, setDataCls] = useState<any>({});
    const [padGirCls, setPadGirCls] = useState<ItemWithChildItems[]>([]);
    const { id } = useParams();

    useEffect(() => {
        id && getApplicationIssuedDoc(id).then((val) => {
            setDate(val);

        });
        getApplicationIssuedDocCls().then((val) => {
            setDataCls(val);
        });
        getPadGir().then((val) => {
            setPadGirCls(val);
        });
    }, []);




    return (
        <>
            <Row>
                <Col >
                    <Form.Group className="" controlId="startDate">
                        <Form.Label>Galiojimo pradžia</Form.Label>
                        <Form.Control type="text" readOnly value={data?.prsLeidGaliojimoPradzia && (new Date(data?.prsLeidGaliojimoPradzia)).toISOString().substring(0, 10) || ""} />
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="" controlId="endDate">
                        <Form.Label>Galiojimo pabaiga</Form.Label>
                        <Form.Control type="text" readOnly value={data?.prsLeidGaliojimoPabaiga && (new Date(data?.prsLeidGaliojimoPabaiga)).toISOString().substring(0, 10) || ""} />
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="" controlId="continueToDate">
                        <Form.Label>Pratęsta iki</Form.Label>
                        <Form.Control type="text" readOnly value={data?.prsLeidPratestasIki && (new Date(data?.prsLeidPratestasIki)).toISOString().substring(0, 10) || ""} />
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="" controlId="woodRenewDate">
                        <Form.Label>Miško atkūrimo terminas</Form.Label>
                        <Form.Control type="text" readOnly value={data?.prsLeidMskAtkurimoTerminas && (new Date(data?.prsLeidMskAtkurimoTerminas)).toISOString().substring(0, 10) || ""} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col >
                    <Form.Group className="" controlId="legalBasis">
                        <Form.Label>Teisinis pagrindas</Form.Label>
                        <Form.Control type="text" readOnly value={dataCls.TEISINIAI_PAGRINDAI && (dataCls.TEISINIAI_PAGRINDAI as LkmpClassifierDto[]).find((val) => val.key == data?.prsLeidTeisinisPagrindas)?.display } />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"MIŠKO SAVININKAI"} <Badge bg="secondary">{data?.owners.length}</Badge>
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                {
                                    data?.owners.map((owner, i) => {
                                        return (
                                            <Col key={`owner_col_${i}`} lg="4">
                                                <Card style={{ marginTop: "10px" }}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={owner.isOrganization ? faCity : faUser} /> {owner.name + ", " + owner.code}</Form.Label>
                                                        </Row>

                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faPhone} /> {owner.phone}</Form.Label>
                                                        </Row>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faEnvelope} /> {owner.email}</Form.Label>
                                                        </Row>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faLocationDot} /> {owner.address}</Form.Label>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"MIŠKO VIETA"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col >
                                    <Form.Group className="" controlId="cadastral">
                                        <Form.Label>Kadastrinis nr.</Form.Label>
                                        <Form.Control type="text" readOnly value={data?.prsSklypoKadastrinisNr} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="manucipality">
                                        <Form.Label>Savivaldybė</Form.Label>
                                        <Form.Control type="text" readOnly value={data?.savivaldybe} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"MIŠKO KIRTIMAS"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col >
                                    <Form.Group className="" controlId="vmuPad">
                                        <Form.Label>VMU Padalinys</Form.Label>
                                        <Form.Control type="text" readOnly value={(padGirCls.length > 0) ? padGirCls.find(v => v.id == data?.prsLeidVmuPadalinys)?.name : data?.prsLeidVmuPadalinys} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="girinkija">
                                        <Form.Label>Girininkija</Form.Label>
                                        <Form.Control type="text" readOnly value={(padGirCls.length > 0) ? padGirCls.find(v => v.id == data?.prsLeidVmuPadalinys)?.children?.find(v => v.id == data?.prsLeidGirininkija)?.name :  data?.prsLeidGirininkija} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <Form.Group className="" controlId="kvartalas">
                                        <Form.Label>Kvartalas</Form.Label>
                                        <Form.Control type="text" readOnly value={data?.prsLeidMskKvartaloNumeris} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="sklypas">
                                        <Form.Label>Sklypas</Form.Label>
                                        <Form.Control type="text" readOnly value={data?.prsLeidMskSklypoNumeris} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="plotas">
                                        <Form.Label>Plotas</Form.Label>
                                        <Form.Control type="text" readOnly value={data?.prsLeidMskKertamasPlotas} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <Form.Group className="" controlId="vyrMed">
                                        <Form.Label>Vyraujantys medžiai</Form.Label>
                                        <Form.Control type="text" readOnly value={dataCls.MEDZIU_RUSYS && (dataCls.MEDZIU_RUSYS as LkmpClassifierDto[]).find(v => v.key == data?.prsLeidMskVyraujantysMedziai)?.display} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="miskuGrupe">
                                        <Form.Label>Miškų grupė</Form.Label>
                                        <Form.Control type="text" readOnly value={dataCls.MISKO_GRUPES && (dataCls.MISKO_GRUPES as LkmpClassifierDto[]).find(v => v.key == data?.prsLeidMskGrupe)?.display} />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="" controlId="kirtimoRusis">
                                        <Form.Label>Kirtimo rūšis</Form.Label>
                                        <Form.Control type="text" readOnly value={dataCls.KIRTIMO_RUSYS && (dataCls.KIRTIMO_RUSYS as LkmpClassifierDto[]).find(v => v.key == data?.prsLeidMskKirtimoRusis)?.display} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"MIŠKO ATKŪRIMO BŪDAS"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col className="col-4" >
                                    <Form.Group className="" controlId="atBud">
                                        <Form.Control type="text" readOnly value={dataCls.ATKURIMO_BUDAI && (dataCls.ATKURIMO_BUDAI as LkmpClassifierDto[]).find(v => v.key == data?.prsLeidMskAtkurimoBudas)?.display} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"APRIBOJIMAI"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                {dataCls.APRIBOJIMAI &&

                                    (dataCls.APRIBOJIMAI as LkmpClassifierDto[]).map((v, i) =>
                                    (
                                        <Col key={`apribojimas_${i}`} className="col-6">
                                            <Card className="mt-1" style={data && data.restrictions.includes(v.key) ? { background: 'rgba(23, 94, 0, 0.1)' } : undefined}>
                                                <Card.Body>
                                                    <Form.Check
                                                        disabled
                                                        defaultChecked={data && data.restrictions.includes(v.key)}
                                                        type="checkbox"
                                                        label={v.display}
                                                        id={`apribojimas_${i}_cb`}
                                                        className="not-disabled"
                                                    />

                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    )

                                    )


                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"PAPILDOMA INFORMACIJA"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                {dataCls.PAPILDOMA_INFORMACIJA &&

                                    (dataCls.PAPILDOMA_INFORMACIJA as LkmpClassifierDto[]).map((v, i) =>
                                    (
                                        <Col key={`papild_info_${i}`} className="col-6">
                                            <Card className="mt-1" style={data && data.additionalInfo.includes(v.key) ? { background: 'rgba(23, 94, 0, 0.1)' } : undefined}>
                                                <Card.Body>
                                                    <Form.Check
                                                        disabled
                                                        defaultChecked={data && data.additionalInfo.includes(v.key)}
                                                        type="checkbox"
                                                        label={v.display}
                                                        id={`papild_info_${i}_cb`}
                                                        className="not-disabled"
                                                    />

                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    )

                                    )


                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"YPATINGOS SĄLYGOS"}
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col >
                                    <Card  style={{ background: 'rgba(23, 94, 0, 0.1)' }}>
                                        <Card.Body>
                                            {data?.specialTerms}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}