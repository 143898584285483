import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { RequestHistoryItem } from "../../resources/application";

interface Props {
    appHistory: RequestHistoryItem[];
}

export const AppHistory: React.FC<Props> = (props) => {
    return (
        <Card style={{ marginTop: "10px" }}>
            <Card.Header>
                {""}
            </Card.Header>
            <Card.Body>
                <Container fluid>
                    <Row>
                        <Col md="6" >
                            <ul className="timeline">
                                {props.appHistory.map((item, i) => (
                                    <li key={`pi_timeline_${i}`}>
                                        <Card>
                                            <Card.Header>
                                                <Badge bg={item.stateCode == "ISSUED" ? "primary" : item.stateCode == "CANCELLED" ? "danger" : "warning"}>{item.stateName}</Badge>

                                                <br />
                                                <strong>{item.date} {item.time}</strong>

                                            </Card.Header>
                                        </Card>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}