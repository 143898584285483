import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Modal, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ApiError } from "../resources/api";
import { ErrorMessage, ErrorContext, ErrorContextValue } from "./ErrorContext";
import { Navigate } from "react-router";

interface State {
    value: ErrorContextValue;
    redirect: boolean;    
}

export class ErrorBoundary extends React.Component<{children?: ReactNode}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            value: {
                errorMessage: undefined,
                setError: this.setError,
                setErrorMessage: (errorMessage, redirect) =>
                    this.setState({
                        value: { ...this.state.value, errorMessage },
                        redirect: !!redirect,
                    }),
            },
            redirect: false,
        };
    }

    private setError = (error: any, redirect?: boolean) => {
        let message: ErrorMessage;
        if (error instanceof ApiError) {
            message = { messageKey: error.message, debugInfo: error.debugInfo };
        } else if (error instanceof Error) {
            message = { messageKey: "error.defaultError", debugInfo: { error: error.message } };
        } else {
            message = { messageKey: "error.defaultError", debugInfo: { error: error + "" } };
        }

        this.state.value.setErrorMessage(message, redirect);
    };

    componentDidCatch(error: any, errorInfo: any) {
        this.state.value.setError(error, true);
    }

    render() {
        if (this.state.redirect) {
            this.setState({
                ...this.state,
                redirect: false,
            });

            return (
                <Navigate
                    to={{
                        pathname: "/",
                    }}
                    replace
                />
            );
        }

        return (
            <ErrorContext.Provider value={this.state.value}>
                {this.state.value.errorMessage == null && this.props.children}
                <ErrorMessageModal message={this.state.value.errorMessage} hide={() => this.state.value.setErrorMessage(undefined)} />
            </ErrorContext.Provider>
        );
    }
}

interface ErrorMessageModalProps {
    message?: ErrorMessage;
    hide: () => void;
}

const ErrorMessageModal: React.FC<ErrorMessageModalProps> = ({ message, hide }) => {
    const { t } = useTranslation();

    if (message == null) {
        return null;
    }

    let debugInfo: any[] = [];
    if (process.env.REACT_APP_DEBUG === "true") {
        debugInfo = Object.entries(message.debugInfo).map(([key, value]) => {
            return (
                <tr key={key}>
                    <td>{key}</td>
                    <td>
                        <pre>{typeof value === "object" ? JSON.stringify(value) : String(value)}</pre>
                    </td>
                </tr>
            );
        });
    }

    const messageString = message.messageKey ? t(message.messageKey) : t("error.defaultError");

    return (
        <Modal show={!!message} onHide={hide} centered size="lg" scrollable>
            <Modal.Header closeButton>
                <Modal.Title>{t("error.modalTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "500px" }}>
                <Alert variant="danger">{messageString}</Alert>
                {debugInfo.length > 0 && (
                    <Table responsive striped bordered hover size="sm">
                        <tbody>{debugInfo}</tbody>
                    </Table>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={hide}>
                    {t("error.ok")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
