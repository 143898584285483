import React, { useMemo, useState, useContext } from "react";
import { RouteObject,Outlet } from "react-router-dom";
import { Notifications } from "./Notifications";
import { AuthContext, Authorization } from "./AuthContext";
import { LoginResult, WebSessionInfo} from "./UserService";
import { ErrorBoundary } from "./ErrorBoundary";

import "../theme/default/style.scss";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "./LocaleContext";
import DefaultLayout from "./Layout/DefaultLayout";
import { isSessionValid, toAuthorizationViisp } from "../resources/auth";

interface Props {
    routes?: RouteObject[];
}

export type IPageType = "strapi-page" | "form-page" | "category-page";

export interface LayoutProps {
    path: string; // we change _path_ from optional to required
    title?: string;
    titleEn?: string;
    componentPath?: string;
    hide?: boolean;
    category?: string;
    requiresAuth?: boolean;
    menu?: "top" | "bottom" | "both";
    layout?: "admin" | "page" | "both" | "none"; // both - means admin and page (not authorized) layouts
    priority?: number;
    icon?: string;
    role?: "ROLE_L03" | "ROLE_L04"; // add all roles for all routes, that are required to show menus
    type?: string; // type of the component if the route is provided without ':type'
    centerContent?: boolean;
    pageType?: IPageType;
    hideWhiteBackground?: boolean;

}

const App: React.FC<Props> = ({ routes }) => {
    const { i18n } = useTranslation();
    const auth = useContext(AuthContext);
    const [authState, setAuthState] = useState(auth);

    const updateAuthState = (newAuth?: Authorization) => {
        // REACTIVITY ISSUES INSIDE THE OLD CODE!!!!!!!!!!!!!
        // for provider we should always use state if we want reactive values        
        setAuthState(Object.assign({}, newAuth ? newAuth : auth));
    };
    auth.updateAuthState = updateAuthState;

    useMemo(async () => {        
        if(localStorage.getItem("session") !== null){
            const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>
            await isSessionValid().then(
                async (val) => {
                    val && val === "OK" &&  setAuthState(await toAuthorizationViisp(session).then(p => { return p; }));  
                }
            ).catch(() => {
                localStorage.removeItem("session");
            });
        } else {
            return  auth.updateAuth(new Authorization());
        }
    }, []);


    return (
        <ErrorBoundary>
            <LocaleContext.Provider value={i18n.language}>
                <AuthContext.Provider value={authState}>
                    <Notifications>
                        <DefaultLayout>
                            <Outlet />
                        </DefaultLayout>
                    </Notifications>
                </AuthContext.Provider>
            </LocaleContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
