import React, { ReactNode } from 'react';
import HeaderMenu from './HeaderMenu';
import TopbarBase from './Topbar/TopbarBase';
import { AuthContext } from '../../../AuthContext';
import { LinkContainer } from 'react-router-bootstrap';
import './HeaderBase.scss';

interface Props {
  warningTitle?: string;
  showWarning?: boolean;
  children?: ReactNode;
}
class HeaderBase extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): ReactNode {
    const links = [
      {
        title: 'Viešoji informacija',
        titleEn: 'Public information',
        url: '/public-info',
      },
      { title: 'Apie ALIS', titleEn: 'About ALIS', url: '/about' },
      { title: 'Pranešimai', titleEn: 'Announcements', url: '/articles' },
      { title: 'DUK', titleEn: 'FAQ', url: '/faq' },
    ];
    return (
      <AuthContext.Consumer>
        {(context) => (
          <>
            <div
              id='kt_header'
              className={`kt-header kt-grid__item  kt-header--fixed${
                !context.authenticated ? ' public' : ''
              }`}
            >
              {context.authenticated ? (
                ''
              ) : (
                <LinkContainer to='/'>
                  <div className='index-logo d-flex align-items-center'>
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/picts/forest_icon_test.svg'
                      }
                      alt=''
                    />
                  </div>
                </LinkContainer>
              )}
              <HeaderMenu links={[]} />
              <TopbarBase menu={links} children={this.props.children} />
            </div>
            {this.props.showWarning && (
              <div
                style={{
                  position: 'fixed',
                  width: '100%',
                  backgroundColor: '#FFEDCB',
                  textAlign: 'center',
                  color: 'red',
                }}
                className={`content-page kt-container kt-grid__item--fluid kt-grid__item`}
              >
                {this.props.warningTitle}
              </div>
            )}
          </>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default HeaderBase;
