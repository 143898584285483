import React from "react";
import { Company} from "../resources/types";
import { logoutSession } from "../resources/auth";

export class Authorization {
    organization?: Company;    
    roles?: string[];    
    authenticated: boolean = false;    
    updateAuthState?: (newAuth?: Authorization) => void;
    logout = async () => {
        await logoutSession()
            .then(() => {
                console.log("Session is invalidated.");                
            }
        )
        .catch(() => {
            console.log("Something is wrong with logout service."); 
        });
        localStorage.removeItem("session");
        window.location.replace(process.env.PUBLIC_URL);
        this.authenticated=false;
    };

    updateAuth = (auth: Authorization) => {
        this.organization = auth.organization;
        this.roles = auth.roles;
        this.authenticated = auth.authenticated;        
        if (this.updateAuthState){
            this.updateAuthState();
        } 
    }

    hasRole = (role: string) =>  {
        return !!this.roles && this.roles.indexOf(role) > -1;
    }
}

const unauthorized: Authorization = new Authorization();
export const AuthContext = React.createContext(unauthorized);
