import { apiGet, apiPut } from "./api";
import { LoginResult, WebSessionInfo } from "./auth";

export interface ProfileDto {
    address: string;
    email: string;
    infoByEmail: boolean;
    name: string;
    orgCode: string;
    orgName: string;
    position: string;
    surname: string;
    tel: string;
}

export async function getProfile(): Promise<ProfileDto> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return await apiGet(`/lkmp/profile/get-profile/`, { userToken: { type: "Bearer", token: session.token } });
}

export async function updateProfile(profile: ProfileDto): Promise<ProfileDto> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return await apiPut(`/lkmp/profile/update-profile/`, { userToken: { type: "Bearer", token: session.token } }, profile);
}