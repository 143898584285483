import React, { ChangeEvent, useCallback, useRef, RefObject } from "react";
import { useField, useFormikContext } from "formik";
import Button from "react-bootstrap/Button";
import { Trans } from "react-i18next";

import { fieldValid } from "./LkmpFormField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { Form, Row } from "react-bootstrap";
import DragAndDrop from "./DragAndDrop";

library.add(faTimes);

export const LkmpFormFileField: React.FC<{ name: string, showClearBtn: boolean | false }> = ({ name, showClearBtn }) => {
	const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();
	const [{ value }, meta] = useField<File>(name);
	const inputRef = useRef<HTMLInputElement>(null);

	const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget && e.currentTarget.files) {
			setFieldValue(name, e.currentTarget.files[0], true);
		}
	}, [setFieldValue, name]);

	const clear = useCallback((current: HTMLInputElement) => {

		current.value = "";
		setFieldValue(name, null);

	}, [setFieldValue, name]);

	const valid = fieldValid(meta, submitCount);

	return (		
		<Form>
			<Row className="px-2 my-2">
				{!value && <DragAndDrop className="drop-file" handleDrop={(files: FileList) => {
					if (files && files.length > 0) {
						setFieldValue(name, files[0], true);
					}
				}} />}
				<input
					ref={inputRef}
					name={name}
					style={{ display: "none" }}
					type="file"
					onChange={onChange}
				/>
				{!value && (
					<Button type="button" variant="outline-secondary" className="drop-button"
						onClick={() => {
							setFieldTouched(name, true, false);
							inputRef.current != null && inputRef.current.click();
						}}>
						<Trans i18nKey="paraiskaFiles.failasIkelkite" />
					</Button>
				)}
				{showClearBtn && !!value && (
					<Button variant="warning" size="sm" className="mr-3" type="button" onClick={() => inputRef.current != null && clear(inputRef.current)} title={'Išvalyti'}>
						<FontAwesomeIcon icon="times" />
					</Button>
				)}
				{!!value && <Form.Control className="file-name w-auto" plaintext readOnly value={value.name} {...valid} />}
				{/* use d-block class to force display of feedback */}
				{valid.isInvalid && <Form.Control.Feedback type="invalid" className="d-block">{meta.error}</Form.Control.Feedback>}
			</Row>

		</Form>
	);
};