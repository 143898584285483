import React, { ReactNode, useContext } from 'react'
import { AuthContext } from '../../../AuthContext'
import { IPageType } from '../../../../permitRoutes'

interface Props {
  fluidWidth?: boolean
  hideWhiteBackground?: boolean
  children?: ReactNode
}

// const { authenticated } = useContext(AuthContext);
class ContentPage extends React.Component<Props> {
  static contextType = AuthContext

  get authenticated() {
    return false
  }

  render(): ReactNode {
    return (
      <div className={`content-page kt-container kt-grid__item--fluid kt-grid__item${ this.props.fluidWidth ? ' kt-container--fluid' : ''}`}>
        <div className={!this.props.hideWhiteBackground ? `kt-portlet${this.authenticated ? ' mb-0' : ''}` : 'bg-transparent'}>
          {
            <div className={this.authenticated ? "kt-portlet__body" : undefined}>              
              {this.props.children}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default ContentPage
