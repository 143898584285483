import { Badge, Button, Card, CloseButton, Col, Form, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { AppFileValues, Data, EvaluationData, fetchApplicationData, getEvaluationsDataByAppIdL, putAssessor, submitEvaluation } from "../../resources/application";
import { Trans, useTranslation } from "react-i18next";
import { LkmpFormDateField } from "../form/LkmpFormDateField";
import FormGroupComponent from "../form/FormGroupComponent";
import { LkmpFormField } from "../form/LkmpFormField";
import { EmptySelectOption } from "../form/EmptySelectOption";
import { LkmpFormCheckField } from "../form/LkmpFormCheckField";
import 'antd/dist/antd.min.css';
import 'ol/ol.css';
import './react-geo.css';
import { MapComponent, SimpleButton } from "@terrestris/react-geo";
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import './Application.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCity } from '@fortawesome/free-solid-svg-icons/faCity';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { useAsyncValue, useParams } from "react-router-dom";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik } from "formik";
import { LkmpAppFiles } from "../form/LkmpAppFiles";
import { setValidationSchemaLocale } from "../common/validation";
import * as yup from "yup";
import { log } from "console";

interface Props {
    data?: any;
    onStateChange: (data: any[]) => void;
}


export const AppEvaluationC: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [evaData, setEvaData] = useState<EvaluationData>((useAsyncValue() as any).data[0]);

    const updateAssessor = useCallback((evaId: string) => {
        putAssessor(evaId).then(() => {
            getEvaluationsDataByAppIdL(id || "0").then((val) => {
                setEvaData(val.data[0]);
            })
        })
    }, [evaData]);

    const submitEval = useCallback((evaData: EvaluationData) => {
        confirmAlert({
            title: 'IŠVADOS PATEIKIMAS',
            message: 'Jūsų suformuota išvada bus pateikta VĮ Valstybinė miškų tarnyba atsakingam už paraiškos nagrinėjimą asmeniui. Ar tikrai norite pateikti išvadą?',
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: undefined,
            customUI: ({ onClose, title, message }) => {
                return (
                    <Card>
                        <Card.Header>{title}</Card.Header>
                        <Card.Body>{message}</Card.Body>
                        <Card.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button style={{ marginRight: "5px" }} type="button" variant="outline-primary" size="sm" title="Taip" onClick={() => {
                                submitEvaluation(evaData).then(() => {
                                    getEvaluationsDataByAppIdL(id || "0").then((val) => {
                                        setEvaData(val.data[0]);
                                        props.onStateChange(val.data);
                                    })
                                });
                                onClose();
                            }} >
                                <i className="la la-check mr-0"></i> Taip
                            </Button>
                            <Button type="button" variant="outline-danger" size="sm" title="Ne" onClick={() => onClose()} >
                                <i className="la la-times mr-0"></i> Ne
                            </Button>
                        </Card.Footer>
                    </Card>
                );
            }
        });
    }, [evaData]);

    const validationSchemaProvider = useCallback(() => {
        setValidationSchemaLocale(t);

        return yup
            .object({
                prv_pastabos: yup.string().required(),
                // leidimasFiles: yup.array<AppFileValues>(
                //     yup.object<AppFileValues>().test("isFileUploaded", "", function (value) {
                //         if (!value.tipas) {
                //             if (!!value.file || (!!value.fileName && !!value.fileUrl))
                //                 return new yup.ValidationError("Būtina pasirinkti dokumento tipą", undefined, "leidimasFiles");
                //         }
                //         return false;
                //     })
                // ),
            })            
    }, [t]);


    return (
        <Card style={{ marginTop: "10px" }}>
            <Card.Body>

                {evaData && evaData.vertintojas === "N" && evaData.prv_vertinimas === "L" && (
                    <Card>
                        <Card.Body>
                            <Row className="application-document header">
                                <Col xs="6" sm="6" md="4">
                                    <strong>Vertintojas:</strong>
                                    {evaData.usr_person_name + " " + evaData.usr_person_surname}
                                </Col>
                                <Col xs="6" sm="6" md="4">
                                    <Button type="button" variant="outline-primary" size="sm" title="Prisiskirti" onClick={() => updateAssessor(evaData.prv_id)} >
                                        Prisiskirti sau
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                )}
                {evaData && evaData.vertintojas === "T" && evaData.prv_vertinimas === "L" && (

                    <Formik
                        validationSchema={validationSchemaProvider}
                        initialValues={evaData}
                        enableReinitialize={true}
                        onSubmit={submitEval}
                    >
                        {
                            ({ handleSubmit, values, setFieldValue, isValid, errors }) => {
                                return (
                                    <>
                                        <Card>
                                            <Card.Body>
                                                <Row className="application-document header">
                                                    <Col xs="6" sm="6" md="4">
                                                        <strong>Vertintojas:</strong>
                                                        {evaData.usr_person_name + " " + evaData.usr_person_surname}
                                                    </Col>
                                                    <Col xs="6" sm="6" md="4">
                                                        <>
                                                            <Button type="button" variant="outline-primary" size="sm" title="Priimti" onClick={(e) => {setFieldValue("action", "SUBMIT"); handleSubmit()
                                                            }
                                                        } 
                                                            >
                                                                <i className="la la-check mr-0"></i> Išduoti
                                                            </Button>
                                                            {" "}
                                                            <Button type="button" variant="outline-danger" size="sm" title="Atmesti" onClick={() => {setFieldValue("action", "CANCEL"); handleSubmit() }} >
                                                                <i className="la la-times mr-0"></i> Atmesti
                                                            </Button>
                                                        </>

                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Body>
                                                <Row className="">
                                                    <Col lg="12">
                                                        <Card>
                                                            <Card.Header>{"Pastaba"}</Card.Header>
                                                            <Card.Body>
                                                                <LkmpFormField name="prv_pastabos">
                                                                    <Form.Control as="textarea" rows={3} />
                                                                </LkmpFormField>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Body>
                                                <Row className="">
                                                    <Col lg="12">
                                                        <Card>
                                                            <Card.Header>{"Dokumentai"}</Card.Header>
                                                            <Card.Body>
                                                                <LkmpAppFiles name="files" />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </>
                                )
                            }
                        }
                    </Formik>
                )}
                {
                    evaData === undefined && (
                        <Card>
                            <Card.Body>
                                <Row className="application-document header">
                                    <Col xs="6" sm="6" md="4">
                                        {"Vertinimo nėra"}
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    )
                }
            </Card.Body>
        </Card>
    );

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this file?</p>
                    <button onClick={onClose}>No</button>
                    <button
                        onClick={() => {
                            //this.handleClickDelete();
                            onClose();
                        }}
                    >
                        Yes, Delete it!
                    </button>
                </div>
            );
        }
    });


}