import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Await, useAsyncError, useAsyncValue, useLoaderData, useNavigate } from "react-router-dom";
import { KTButton } from "../../components/Thematic/KTButton";
import KTSpinner from "../../components/Thematic/KTSpinner";
import { AuthContext } from "../AuthContext";
import { LkmpFormDateField } from "../form/LkmpFormDateField";
import { LkmpFormField } from "../form/LkmpFormField";
import "@glideapps/glide-data-grid/dist/index.css";
import { ApplicationReactTable} from "./ApplicationReactTable";
import { ApplicationFilterValue} from "../../resources/application";
import { ApplicationsBrowseRow, BrowseFormSearchResult, Spr_paging_ot } from "../../resources/dto";
import { Classifier } from "../../resources/classifiers";
import { KTFilterForm } from "../../components/Thematic/KTFilterForm/KTFilterForm";
import { ErrorContext } from "../ErrorContext";

export const Applications: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);

  const [filterValues, setFilterValues] = useState<ApplicationFilterValue>({
    submittedFrom: undefined,
    submittedTo: undefined,
    applicationNr: '',
    cadastralNr: '',
    municipalityId: "",
    ownerApplications: false,
    owner: "",
    applicationState: "",
    stateDateFrom: undefined,
    stateDateTo: undefined,
    executor: "",
    applicationType: "",
  });

  function toFilterValue(values:BrowseFormSearchResult<ApplicationsBrowseRow>):ApplicationFilterValue {

    return {
      submittedFrom: undefined,
      submittedTo: undefined,
      applicationNr: '',
      cadastralNr: '',
      municipalityId: "",
      ownerApplications: false
    }
  }

  useEffect(() => {
    const pagingParams: Spr_paging_ot = {
      cnt: 0,
      order_clause: "",
      page_size: 20,
      skip_rows: 0,
    }
  });

  const handleFilter = useCallback((values:ApplicationFilterValue) => {
    setFilterValues(values);    
  },[]);

  const data:any = useLoaderData();
  const municipalitiesData:any = useAsyncValue();

  return (
    <React.Suspense fallback={<KTSpinner />}>
      <Await resolve={data && data.data} errorElement={<ReviewsError />}
      children={(muni) => (
        <>
          <KTFilterForm
            formikInitialValues={filterValues}
            onSubmit={handleFilter}
            buttons={
              <KTButton
                type='submit'
                disabled={busy}
                showSpinner={busy}
                className='mb-3'
                
              >
                {t('common.button.filter')}
              </KTButton>
            }
            buttonsBreakpoint={"break-llg"}
            filterShort={
              ({ handleSubmit, values, handleChange }) => {
                return (
                    <Row>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Pateikta nuo"}</span>
                        <LkmpFormDateField name='submittedFrom' />
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Pateikta iki"}</span>
                        <LkmpFormDateField name='submittedTo' />
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Prašymo Nr."}</span>
                        <LkmpFormField name='applicationNr'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Kadastrinis Nr."}</span>
                        <LkmpFormField name='cadastralNr'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Savivaldybė"}</span>
                        <LkmpFormField name='municipalityId'>
                          <Form.Control as='select'>
                            <option value=''>- Pasirinkite -</option>
                            {(muni as Classifier[]).sort((a, b) => {
                              return a.display.toUpperCase().localeCompare(b.display.toUpperCase())
                            }).map((v:Classifier) => {
                              return (<option id={`muni-${v.key}`} value={v.key}>{v.display}</option> )
                            })
                            }
  
                          </Form.Control>
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Mano paraiškos"}</span>
                        <LkmpFormField name='ownerApplications'>
                          <Form.Check style={{ paddingTop: "5px" }}
                            type={"checkbox"}
                          />
                        </LkmpFormField>
                      </Col>                      
                    </Row>
                );
              }
            }
            filterExtended={
              ({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
            }) => (
                <>
                  <Row>                    
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Savininkas"}</span>
                      <LkmpFormField name='owner'>
                        <Form.Control type='text' />
                      </LkmpFormField>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Būsena"}</span>
                      <LkmpFormField name='applicationState'>
                        <Form.Control as='select'>
                          <option value=''>- Pasirinkite -</option>
                          <option value='CHECKING_LOT'>Tikrinama biržė</option>
                          <option value='LOT_NOT_PROVIDED'>Nepateikta biržė</option>
                          <option value='REJECTED'>Atmestas</option>
                          <option value='CHECKING_INTERSECTIONS'>Tikrinamos sankirtos</option>
                          <option value='INTERSECTIONS_FOUND'>Sankirtos nustatytos</option>
                          <option value='INTERSECTIONS_NOT_FOUND'>Sankirtos nenustatytos</option>
                          <option value='CHECKING_KPD'>Tikrinama KPD</option>
                          <option value='CHECKING_VSTT'>Tikrinama VSTT</option>
                          <option value='CHECKING_KPD_VSTT'>Tikrinama KPD, VSTT</option>
                          <option value='CHECKING_VMT'>Tikrinama VMT</option>
                          <option value='ISSUED'>Išduotas</option>
                          <option value='CANCELLED'>Anuliuotas</option>
                          <option value='EXPIRED'>Pasibaigęs</option>
                          <option value='EXTENDED'>Pratęstas</option>
                        </Form.Control>
                      </LkmpFormField>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Būsenos data nuo"}</span>
                      <LkmpFormDateField name='stateDateFrom' />
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Būsenos data iki"}</span>
                      <LkmpFormDateField name='stateDateTo' />
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Vykdytojas"}</span>
                      <LkmpFormField name='executor'>
                        <Form.Control as='select'>
                          <option value=''>- Pasirinkite -</option>
                        </Form.Control>
                      </LkmpFormField>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='3'>
                      <span>{"Prašymo tipas"}</span>
                      <LkmpFormField name='applicationType'>
                        <Form.Control as='select'>
                          <option value=''>- Pasirinkite -</option>
                          <option value='CUT_NOTIFICATION'>Pranešimas</option>
                          <option value='PERMISSION_TO_CUT'>Leidimas</option>
                        </Form.Control>
                      </LkmpFormField>
                    </Col>
                  </Row>
                </>
              )
            }
          >
            <ApplicationReactTable filterVal={filterValues} />
          </KTFilterForm>
          
          

        </>
      )}
      />
    </React.Suspense>
    
  );

  function ReviewsError() {
    const { setError } = useContext(ErrorContext);
    const error: any = useAsyncError();
    const navigate = useNavigate();
    useEffect(() => {
      setError(error);
      if (error.status === 403) {
        navigate('/', { replace: true });
      }
    }, [error, navigate, setError]);
    return <></>;
  }
};



