import React, { ReactNode } from "react";
//import { routes } from "../../../../router";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AuthContext } from "../../../AuthContext";

interface Props {
    mobileAsideToggle: () => void;
    history?: any;
}
/**
 * @expandKey unique category key to expand category links
 */
interface State {
    expandKey: string | undefined;
}

class AsideMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expandKey: "",
        };
    }

    componentDidMount() {
        this.expandWithKey(this.selectedCategory);
    }

    expandWithKey(key?: string) {
        if (this.state.expandKey !== key) {
            this.setState({ expandKey: key });
        } else this.setState({ expandKey: "" });
    }

    showCategoryNumber(category?: string) {
        return category !== "pranesimai" && category !== "Admin";
    }

    isSelectedMenu(menuPath?: string) {
        return menuPath === window.location.hash.replace("#", "");
    }

    get selectedCategory() {
        const { hash, pathname } = window.location;
        const path = hash.length ? hash : pathname;
        if (path.length) {
            const route = undefined; // routes().find((r) => path.includes(r.category as string));
           // if (route) return route.category;
        }
        return "";
    }

    clickLink(e: React.MouseEvent, path?: string) {
        // e.preventDefault();
        // this.props.mobileAsideToggle();
        // if (path && this.props.history) {
        //     setTimeout(() => {
        //         this.props.history.push(path);
        //     }, 0);
        // }
    }

    getCategoryMenus(category: string) {
        return {} //routes().filter((r) => r.showAside && r.pageType !== "category-page" && r.category === category);
    }

    render(): ReactNode {
        return (
            <PerfectScrollbar
                id="kt_aside_menu"
                className="kt-aside-menu kt-scroll"
                data-ktmenu-vertical="1"
                data-ktmenu-scroll="1"
                data-ktmenu-dropdown-timeout="500"
            >
                <ul className="kt-menu__nav ">
                    <li className="kt-menu__section ">
                        <h4 className="kt-menu__section-text"></h4>
                        <i className="kt-menu__section-icon flaticon-more-v2"></i>
                    </li>
                    {/* {
            links.map(route => (
              <li className="kt-menu__item kt-menu__item--submenu kt-menu__item--open" aria-haspopup="true" data-ktmenu-submenu-toggle="hover" key={route.path}>
                <a onClick={() => this.expandWithKey(route.category)} className="kt-menu__link kt-menu__toggle">
                  <span className="kt-menu__link-icon" style={{ fontWeight: "bold", fontSize: "14px" }}>{route.category}</span>
                </a>
                <div className="kt-menu__submenu " kt-hidden-height="160">
                <ul className="kt-menu__subnav">
                  {
                    route.category &&
                    this.getCategoryMenus(route.category).map(rr => {
                      return <li className={`kt-menu__item${this.isSelectedMenu(rr.path) ? " kt-menu__item--active" : ""}`} aria-haspopup="true" key={rr.path}>
                        {
                          rr.path && <Link to={rr.path} className="kt-menu__link ">
                            <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                            <span className="kt-menu__link-text">{rr.title}</span>
                          </Link>
                        }
                      </li>
                    })
                  }
                </ul>
                </div>
              </li>
            ))
          } */}
                    {/* {routes()
                        .filter((r) => r.pageType === "category-page" && r.title !== "")
                        .map((route, i) => (
                            <AuthContext.Consumer key={i}>
                                {(context) => {
                                    return !route.role || context.roles?.find((r) => r === route.role) ? (
                                        (route.category && this.getCategoryMenus(route.category).length > 0 && (
                                            <li
                                                className={`kt-menu__item kt-menu__item--submenu ${
                                                    this.state.expandKey === route.category ? "kt-menu__item--open" : null
                                                }`}
                                                aria-haspopup="true"
                                                data-ktmenu-submenu-toggle="hover"
                                                key={route.path}
                                            >
                                                <a onClick={() => this.expandWithKey(route.category)} className="kt-menu__link kt-menu__toggle">
                                                    {route.icon && <i className={`kt-menu__link-icon ${route.icon}`}></i>}
                                                    <span className="kt-menu__link-text">{route.title}</span>
                                                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                                </a>
                                                <div className="kt-menu__submenu " kt-hidden-height="160">
                                                    <span className="kt-menu__arrow"></span>
                                                    <ul className="kt-menu__subnav">
                                                        {route.category &&
                                                            this.getCategoryMenus(route.category).map((rr) => {
                                                                return (
                                                                    <li
                                                                        className={`kt-menu__item${
                                                                            this.isSelectedMenu(rr.path) ? " kt-menu__item--active" : ""
                                                                        }`}
                                                                        aria-haspopup="true"
                                                                        key={"inner_" + rr.path}
                                                                    >
                                                                        {rr.path && (
                                                                            <Link
                                                                                onClick={(e) => this.clickLink(e, rr.path)}
                                                                                to={rr.path}
                                                                                className="kt-menu__link "
                                                                            >
                                                                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                                                                                    <span></span>
                                                                                </i>
                                                                                <span className="kt-menu__link-text">{rr.title}</span>
                                                                            </Link>
                                                                        )}
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                            </li>
                                        )) ||
                                            ((!route.category || (route.category && this.getCategoryMenus(route.category).length === 0)) && (
                                                <li className={`kt-menu__item`} key={route.path}>
                                                    <Link onClick={(e) => this.clickLink(e, route.path)} to={route.path} className="kt-menu__link ">
                                                        {route.icon && (
                                                            <i className={`kt-menu__link-icon ${route.icon}`}>
                                                                <span></span>
                                                            </i>
                                                        )}
                                                        <span className="kt-menu__link-text">{route.title}</span>
                                                        <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                                    </Link>
                                                </li>
                                            ))
                                    ) : (
                                        <></>
                                    );
                                }}
                            </AuthContext.Consumer>
                        ))} */}
                    <li className={`kt-menu__item`} key={"prasymas"}>
                        <Link onClick={(e) => this.clickLink(e, "/application")} to={"/application"} className="kt-menu__link ">

                            <i className={`kt-menu__link-icon flaticon2-list-3`}>
                                <span></span>
                            </i>
                            <span className="kt-menu__link-text">{"Prašymai"}</span>
                            <i className="kt-menu__ver-arrow la la-angle-right"></i>
                        </Link>
                    </li>
                    <li className={`kt-menu__item`} key={"dokumentai"}>
                        <Link onClick={(e) => this.clickLink(e, "/permit")} to={"/permit"} className="kt-menu__link ">

                            <i className={`kt-menu__link-icon flaticon2-notepad`}>
                                <span></span>
                            </i>
                            <span className="kt-menu__link-text">{"Išduoti dokumentai"}</span>
                            <i className="kt-menu__ver-arrow la la-angle-right"></i>
                        </Link>
                    </li>
                </ul>
            </PerfectScrollbar>
        );
    }
}

// export default AsideMenu

// @ts-ignore
export default AsideMenu;
