import { LkmpFormFieldPastaba, fieldValid } from "./LkmpFormField";
import React, { ChangeEvent } from "react";
import { useField, useFormikContext } from "formik";
import FormCheck from "react-bootstrap/FormCheck";
import Feedback from "react-bootstrap/Feedback";

interface Props {
    name: string;
    label?: React.ReactNode;
    pastaba?: string;
    id?: string;
    className?: string;

    onChange?: (value: boolean) => void;
    readOnly?: boolean;
    defaultChecked?: boolean;
}

export const LkmpFormCheckField: React.FC<Props> = ({
    id,
    name,
    label,
    pastaba,
    onChange,
    className,
    readOnly,
    defaultChecked,
}) => {
    const [{ value }] = useField<boolean>(name);

    const [field, meta] = useField(name);

    const { submitCount } = useFormikContext<any>();

    const fieldProps = {
        ...field,
        ...fieldValid(meta, submitCount),
    };

    return (
        <FormCheck
            className={className ? className : undefined}
            readOnly={readOnly}
            style={{ pointerEvents: readOnly ? "none" : "all" }}
        >
            <FormCheck.Input
                id={id ? id : undefined}
                type="checkbox"
                defaultChecked={defaultChecked}
                readOnly={readOnly}
                {...fieldProps}
                checked={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (!readOnly) {
                        if (onChange) {
                            onChange(e.target.checked);
                        }
                        fieldProps.onChange(e);
                    }
                }}
            />
            {label && <FormCheck.Label htmlFor={id ? id : undefined}>{label}</FormCheck.Label>}
            <Feedback type="invalid">{meta.error}</Feedback>
            <LkmpFormFieldPastaba pastaba={pastaba} />
        </FormCheck>
    );
};
