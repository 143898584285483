import { SetStateAction, useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TableColumn } from "react-data-table-component/dist/src/DataTable/types";
import { apiPost } from "../../resources/api";
import { OrderDto, PageRespDto } from "../../resources/types";
import { ErrorContext } from "../ErrorContext";
import { ApplicationFilterValue, getApplicationsList, toApplicationFilterDto } from "../../resources/application";
import { AdvancedSearchParameterStatement, ApplicationsBrowseRow, ExtendedSearchCondition, ExtendedSearchParam, ExtendedSearchParamValue, ExtendedSearchUpperLower, Spr_paging_ot, toDateDto } from "../../resources/dto";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
	filterVal: ApplicationFilterValue;
	children?: React.ReactNode;
}

export const ApplicationReactTable: React.FC<Props> = (props: Props) => {

	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [order, setOrder] = useState<OrderDto>({ field: 'prs_id', dir: 'ASC' });

	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();

	interface ApplicationTableRowDto {
		prs_id: number;
		prs_numeris: string;
		prs_savininkas: string;
		prs_sklypo_kadastrinis_nr: string;
		prs_savivaldybe: string;
		prs_vykdytojas: string;
		prb_busena_name: string;
		prb_nustatymo_data: string;
	}

	function toApplicationTableRowDto(data: ApplicationsBrowseRow): ApplicationTableRowDto {
		return {
			prs_id: data.prs_id,
			prs_numeris: data.prs_numeris,
			prs_savininkas: data.prs_savininkas,
			prs_sklypo_kadastrinis_nr: data.prs_sklypo_kadastrinis_nr,
			prs_savivaldybe: data.prs_savivaldybe,
			prs_vykdytojas: data.prs_vykdytojas,
			prb_busena_name: data.prb_busena_name,
			prb_nustatymo_data: data.prb_nustatymo_data
		}
	}

	function fromFilterParam(filterParams: ApplicationFilterValue): AdvancedSearchParameterStatement[] {
		let extSearchArray: AdvancedSearchParameterStatement[] = [];
		if (filterParams.applicationNr) {
			extSearchArray.push(
				{
					paramName: "prs_numeris",
					paramValue: {
						condition: ExtendedSearchCondition.Contains,
						value: filterParams.applicationNr,
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if (filterParams.cadastralNr) {
			extSearchArray.push(
				{
					paramName: "prs_sklypo_kadastrinis_nr",
					paramValue: {
						condition: ExtendedSearchCondition.Contains,
						value: filterParams.cadastralNr,
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if (filterParams.submittedFrom) {
			extSearchArray.push(
				{
					paramName: "prs_sukurimo_data_nuo",
					paramValue: {
						condition: ExtendedSearchCondition.More,
						value: toDateDto(filterParams.submittedFrom),
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if (filterParams.submittedTo) {
			extSearchArray.push(
				{
					paramName: "prs_sukurimo_data_iki",
					paramValue: {
						condition: ExtendedSearchCondition.Less,
						value: toDateDto(filterParams.submittedTo),
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if (filterParams.municipalityId) {
			extSearchArray.push(
				{
					paramName: "prs_savivald_kodas",
					paramValue: {
						condition: ExtendedSearchCondition.Equals,
						value: filterParams.municipalityId,
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if (filterParams.ownerApplications) {
			extSearchArray.push(
				{
					paramName: "mano_vertinimai",
					paramValue: {
						condition: ExtendedSearchCondition.Equals,
						value: "",
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if(filterParams.owner){
			extSearchArray.push(
				{
					paramName: "prs_savininkas",
					paramValue: {
						condition: ExtendedSearchCondition.Contains,
						value: filterParams.owner,
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if(filterParams.applicationState){
			extSearchArray.push(
				{
					paramName: "prb_busena_name",
					paramValue: {
						condition: ExtendedSearchCondition.Equals,
						value: filterParams.applicationState,
						upperLower: ExtendedSearchUpperLower.Regular,
						values: []
					}
				}
			);
		}

		if(filterParams.stateDateFrom){
			extSearchArray.push(
				{
					paramName: "prb_nustatymo_data_nuo",
					paramValue: {
						condition: ExtendedSearchCondition.More,
						value:  toDateDto(filterParams.stateDateFrom),
						upperLower: ExtendedSearchUpperLower.Regular,
						values: []
					}
				}
			);
		}

		if(filterParams.stateDateTo){
			extSearchArray.push(
				{
					paramName: "prb_nustatymo_data_iki",
					paramValue: {
						condition: ExtendedSearchCondition.Less,
						value:  toDateDto(filterParams.stateDateTo),
						upperLower: ExtendedSearchUpperLower.Regular,
						values: []
					}
				}
			);
		}

		if(filterParams.executor){
			extSearchArray.push(
				{
					paramName: "prs_savininkas",
					paramValue: {
						condition: ExtendedSearchCondition.Contains,
						value: filterParams.executor,
						upperLower: ExtendedSearchUpperLower.Lowercase,
						values: []
					}
				}
			);
		}

		if(filterParams.applicationType){
			extSearchArray.push(
				{
					paramName: "prs_leidimo_tipas_name",
					paramValue: {
						condition: ExtendedSearchCondition.Equals,
						value: filterParams.applicationType,
						upperLower: ExtendedSearchUpperLower.Regular,
						values: []
					}
				}
			);
		}
		return extSearchArray;
	}

	const fetchData = async (pageNumber: number, order: OrderDto, psize:number ) => {
		setLoading(true);

		const skip = pageNumber < 2 ? 0 : ((pageNumber-1) * psize) 
		const pagingParams: Spr_paging_ot = {
			cnt: NaN,
			order_clause: (order.field + " " + order.dir),
			page_size: pageSize,
			skip_rows: skip,
			// sum_values: [],
		}

		//   const extendedParm:ExtendedSearchParam[] = [{
		// 	paramName: 'prs_numeris',
		// 	paramValue : {
		// 		condition: ExtendedSearchCondition.Contains,
		// 		value: props.filterVal.applicationNr,
		// 		upperLower: ExtendedSearchUpperLower.Lowercase
		// 	}
		//   }];



		getApplicationsList(pagingParams, new Map(), fromFilterParam(props.filterVal) as ExtendedSearchParam[])
			.then((value) => {
				setData(value.data);
				setTotalRows(value.total_cnt);
				setPageNumber(pageNumber);  
			})
			.catch((error) => {
				console.log("Kazkas negerai !!!!", error);
			}).finally(() => setLoading(false));



		// const filtering = {...toApplicationFilterDto(props.filterVal), pageNumber:pageNumber, pageSize:pageSize, sort: order};
		// console.log(filtering);

		// apiPost("/application/filter",{}, filtering)
		// .then((res:PageRespDto) => {
		// 	setData(res.data);
		// 	setTotalRows(res.total);
		// 	setPageNumber(pageNumber);
		// })
		// .catch(setError)
		// .finally(() => setLoading(false))

	};

	const handlePageChange = (page: number) => {
		fetchData(page, order, pageSize);
	};

	const handlePerRowsChange = async (newPageSize: SetStateAction<number>, pageNumber: any) => {
		setPageSize(newPageSize);
		// apiPost("/application/filter", {}, { ...toApplicationFilterDto(props.filterVal), pageNumber: pageNumber, pageSize: newPageSize, sort: order })
		// 	.then((res: PageRespDto) => {
		// 		setData(res.data);
		// 		setPageSize(newPageSize);
		// 		setPageNumber(pageNumber);
		// 	})
		// 	.catch(setError)
		// 	.finally(() => setLoading(false))
	};

	useEffect(() => {
		fetchData(pageNumber, order, pageSize); // fetch page 1 of users
	}, [props.filterVal]);

	const handleSort = async (column: TableColumn<never>, sortDirection: string) => {
		setOrder({ field: column.id && column.id.toString() || 'prs_id', dir: sortDirection.toUpperCase() })
		fetchData(pageNumber, { field: column.id && column.id.toString() || 'prs_id', dir: sortDirection.toUpperCase() }, pageSize);
	};
	const handleDoubleClick = (row: { prs_id: any; }, event: any) => {
		navigate(`/application/${row.prs_id}`);
	}

	const columns: TableColumn<never>[] = [
		{
			id: 'prs_id',
			name: 'Id',
			selector: (row: any) => row.prs_id,
			sortable: true,
			width: '70px'
		},
		{
			id: 'prs_sukurimo_data',
			name: 'Pateikimo data',
			selector: (row: any) => row.prs_sukurimo_data,
			sortable: true,
		},
		{
			id: 'prs_numeris',
			name: 'Prašymo numeris',
			selector: (row: any) => row.prs_numeris,
			sortable: true,
		},
		{
			id: 'prs_savininkas',
			name: 'Miško savininkas (-ai)',
			selector: (row: any) => row.prs_savininkas,
			sortable: true,
			wrap: true
		},
		{
			id: 'prs_sklypo_kadastrinis_nr',
			name: 'Kadastrinis Nr.',
			selector: (row: any) => row.prs_sklypo_kadastrinis_nr,
			sortable: true,
		},
		{
			id: 'prs_savivaldybe',
			name: 'Savivaldybė',
			selector: (row: any) => row.prs_savivaldybe,
			sortable: true,
		},
		{
			id: 'prs_vertintojas',
			name: 'Vertintojas',
			selector: (row: any) => row.prs_vertintojas,
			sortable: true,
			wrap: true
		},
		{
			id: 'prb_busena_name',
			name: 'Būsena',
			selector: (row: any) => row.prb_busena_name,
			sortable: true,
		},
		{
			id: 'prb_nustatymo_data',
			name: 'Būsena data',
			selector: (row: any) => row.prb_nustatymo_data,
			sortable: true,
		},
		{
			cell: (row: any) => (
				<LinkContainer to={`/application/${row.prs_id}`}>
					<Button type="button" variant="primary" size="sm" title="Peržiūrėti" href="">
						<i className="la la-file mr-0"></i>
					</Button>
				</LinkContainer>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];

	const paginationComponentOptions = {
		rowsPerPageText: 'Eilutės:',
		rangeSeparatorText: 'iš',
		selectAllRowsItem: false,
		selectAllRowsItemText: 'Visos',
	};

	const customStyles = {
		headRow: {
			style: {
				border: 'none',
			},
		},
		headCells: {
			style: {
				color: '#202124',
				fontSize: '14px',
			},
		},
		rows: {
			highlightOnHoverStyle: {
				backgroundColor: 'rgb(230, 244, 244)',
				borderBottomColor: '#FFFFFF',
				borderRadius: '25px',
				outline: '1px solid #FFFFFF',
			},
		},
		pagination: {
			style: {
				border: 'none',
			},
		},
	};

	return (
		<DataTable
			columns={columns}
			data={data}
			progressPending={loading}
			progressComponent={(
				<div className="kt-spinner kt-spinner--lg kt-spinner--brand">
				  <span>&nbsp;</span>
				</div>)}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			paginationComponentOptions={paginationComponentOptions}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			onSort={handleSort}
			noDataComponent={(<><h3>Duomenų nėra</h3></>)}
			sortServer
			customStyles={{
				table: {
					style: { minHeight: '500px' }
				},
				rows: {
					highlightOnHoverStyle: {
						backgroundColor: '#dbd9d9',
						
					},
				},
			}}
			onRowClicked={handleDoubleClick}
			highlightOnHover
			pointerOnHover
		/>
	);

}
