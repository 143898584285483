import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TFunction } from 'i18next'

interface Props {
  links: {title: string, titleEn: string, url: string}[]
  locale: string;
  t: TFunction;
}

class HeaderMenuPC extends React.Component<Props> {
  render(): ReactNode {
		return (
      <>
        {/* <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button> */}
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout- ">
            <ul className="kt-menu__nav">
              {/* <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active">
                <Link to="/permits" className="kt-menu__link kt-menu__toggle-">
                  <span className="kt-menu__link-text">{this.props.t("home.btn.orderPermit") as string}</span>
                  <i className="kt-menu__hor-arrow la la-angle-right"></i>
                </Link>
              </li> */}
              <li className="kt-menu__item kt-menu__item--rel">
                {
                  this.props.links.map(l => (
                    <Link to={l.url} className="kt-menu__link" key={l.title}>
                      <span className="kt-menu__link-text">{this.props.locale === 'lt' ? l.title : l.titleEn}</span>
                    </Link>
                  ))
                }
              </li>
            </ul>
          </div>
        </div>
      </>
		)
	}
}

export const HeaderMenu: React.FC<{links: {title: string, titleEn: string, url: string}[]}> = ({links}) => {

  const { t, i18n } = useTranslation();

  return (
		<HeaderMenuPC links={links} locale={i18n.language} t={t} />
	);
}

export default HeaderMenu
