import { EmptySelectOption } from "./EmptySelectOption";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FieldArray, useField, useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { ErrorContext } from "../ErrorContext";
// import { AlisFileType, readAlisFileTypePagalDokumentoTipas, sortClassifiers } from "../../resources/classifiers";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { emptySelectOptionValue } from "../../resources/types";
// import { downloadFileByUrl } from "../../resources/download";
import { AppFileValues } from "../../resources/application";
import { LkmpFormField, fieldValid } from "./LkmpFormField";
import { LkmpFormFileField } from "./LkmpFormFileField";

library.add(faPlus, faMinus);

const emptyAppFileValues: AppFileValues = {
    tipas: "",
    pavadinimas: "",
    fileName: "",
};

interface LkmpAppFilesProps {
    name: string;
}

export const LkmpAppFiles: React.FC<LkmpAppFilesProps> = ({ name }) => {
    const [field, meta] = useField<AppFileValues[]>(name);
    const { t } = useTranslation();
    const { setError } = useContext(ErrorContext);

    const { submitCount } = useFormikContext<any>();

    const hasValues = field.value && field.value.length > 0;

    const [dokumentoTipai, setDokumentoTipai] = useState<string[]>([]);

    useEffect(() => {
    }, [setDokumentoTipai, setError]);

    /* const downloadFile = useCallback((id: string) => {
		downloadParaiskaFile(id)
			.catch(setError);
	}, [setError]); */
    const downloadFile = useCallback(
        (fileUrl: string, fileName?: string) => {
            // downloadFileByUrl(fileUrl, fileName).catch(setError);
        },
        [setError]
    );

    // const dokumentoTipaiItems = dokumentoTipai.map((s) => (
    //     <option key={s} value={s}>
    //         {s}
    //     </option>
    // ));

    if (meta && meta.value) {
        meta.value.map((val) => {
            if (val?.file?.size && val.file?.size > 10000000) {
                meta.error = "Vienu metu galima įkelti ne didesnius nei 10MB dokumentus.";
            }
        });
        const allFilesSize = meta.value.reduce((sum, files) => sum + (files?.file ? files?.file?.size : 0), 0);
        if (allFilesSize > 50000000) {
            meta.error = "Visų dokumentų dydžių suma negali viršyti 50MB.";
        }
    }

    const valid = fieldValid(meta, submitCount);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => (
                <div>
                    <Table striped bordered hover size="sm" responsive={true}>
                        <thead>
                            <tr>
                                <th>
                                    <Trans i18nKey="paraiskaFiles.failas" />
                                </th>
                                <th>
                                    <Trans i18nKey="paraiskaFiles.veiksmai" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hasValues &&
                                field.value.map((file, index) => (
                                    <tr key={index}>
                                        
                                        <td>
                                            {file.fileUrl != null && (
                                                    <input type="hidden" name={`${name}[${index}].fileUrl`} value={file.fileUrl} />
                                                )}
                                            {file.fileUrl == null && <LkmpFormFileField name={`${name}[${index}].file`} showClearBtn={false} />}
                                            {file.fileUrl != null && (
                                                <Button variant="link" onClick={() => downloadFile(file.fileUrl as string, file.fileName)}>
                                                    {file.fileName}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <Button
                                                type="button"
                                                variant="outline-secondary"
                                                title={"Pašalinti"}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                <FontAwesomeIcon icon="minus" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            {!hasValues && (
                                <tr>
                                    <td colSpan={6}>
                                        {"Prikabintų dokumentų nėra"}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={6}>
                                    <Button
                                        type="button"
                                        variant="outline-secondary"
                                        title={t("paraiskaFiles.pridetiFaila")}
                                        onClick={() => arrayHelpers.push({ ...emptyAppFileValues })}
                                    >
                                        <FontAwesomeIcon icon="plus" />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* use d-block class to force display of feedback */}
                    {valid.isInvalid && typeof meta.error === "string" && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {meta.error}
                        </Form.Control.Feedback>
                    )}
                </div>
            )}
        />
    );
};
