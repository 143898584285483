import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { AppIntersection } from "../../resources/application";

interface Props {
    inters: AppIntersection[];
}


export const AppIntersections: React.FC<Props> = (props) => {
    return (
        <>
            <Row as={Col}>
                {
                    props.inters.map((inter, i) => {
                        return (
                            <Col key={`intersec_col_${i}`} sm="12" md="4">
                                <Row>
                                    <Col>
                                        {(inter.typeCode === "STVK_V" || inter.typeCode === "STVK_P") && <h4>SAUGOMŲ TERITORIJŲ OBJEKTAS</h4>}
                                        {(inter.typeCode === "KVR_V" || inter.typeCode === "KVR_P") && <h4>KULTŪROS PAVELDO OBJEKTAS</h4>}
                                        {(inter.typeCode === "BIIP_V" || inter.typeCode === "BIIP_P") && <h4>BIOLOGINĖS ĮVAIROVĖS INFORMACINĖ SISTEMA</h4>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(inter.typeCode === "STVK_V" || inter.typeCode === "KVR_V" || inter.typeCode === "BIIP_V") && <h5>VERTINIMO METU</h5>}
                                        {(inter.typeCode === "STVK_P" || inter.typeCode === "KVR_P" || inter.typeCode === "BIIP_P") && <h4>PRATĘSIMO METU</h4>}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <ul key={`${i}`} className="timeline">
                                                        <li key={`1${i}`}>
                                                            <Row>
                                                                <h5>Išsiųsta užklausa</h5>
                                                            </Row>
                                                            <Row>
                                                                <strong>{inter.creationDate} {inter.creationTime}</strong>
                                                            </Row>
                                                        </li>
                                                        <li key={`2${i}`}>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Badge bg={inter.stateCode == "SAN" ? "primary" : inter.stateCode == "NER" ? "danger" : "warning"}>{inter.state}</Badge>

                                                                    <br />
                                                                    <strong>{inter.responseDate} {inter.responseTime}</strong>

                                                                </Card.Header>
                                                            </Card>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Col>


                        )
                    })
                }
            </Row>
        </>
    );
}