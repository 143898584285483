import React from 'react';

export interface Notification {
	title: string;
	content?: string;
}

export interface NotificationsContextValue {
	notifications: Notification[];
	pushNotifications: (...newNotifications: Notification[]) => void
}

export const NotificationsContext = React.createContext<NotificationsContextValue>({
	notifications: [],
	pushNotifications: () => {},
});
