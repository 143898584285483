import React, {useCallback, useMemo, useState} from "react";
import {Notification, NotificationsContext, NotificationsContextValue} from "./NotificationsContext";
import Toast from "react-bootstrap/Toast";
import {Trans, useTranslation} from "react-i18next";
interface Props {
	children?: React.ReactNode;
}


export const Notifications: React.FC<Props> = ({children}) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const contextValue = useMemo<NotificationsContextValue>(() => {
		return {
			notifications,
			pushNotifications: (...newNotifications: Notification[]) => setNotifications([...notifications, ...newNotifications]),
		}
	}, [notifications]);

	const {t} = useTranslation();
	const closeNotification = useCallback((notification: Notification) => setNotifications(notifications.filter(n => n !== notification)), [notifications]);
	
	return (
		<NotificationsContext.Provider value={contextValue}>
			
			{children}

			<div className="fixed-top mt-5">
				<div
					style={{
						position: 'absolute',
						top: '50px',
						right: '25px',
					}}
				>
					{notifications.map((notification, index) => (
						<Toast
							key={index}
							className="p-2 bg-secondary"
							delay={5000}
							autohide
							onClose={() => {
								// document.get
								closeNotification(notification)
							}}
						>
							<Toast.Header closeLabel={t('common.button.close')} className="bg-secondary text-white border-0">
								<span className="mr-auto">
									<Trans i18nKey={notification.title}/>
								</span>
							</Toast.Header>
							{notification.content && (
								<Toast.Body className="bg-primary text-white">
									<Trans i18nKey={notification.content}/>
								</Toast.Body>
							)}
						</Toast>
					))}
				</div>
			</div>
		</NotificationsContext.Provider>
	);
};
