import { Badge, Col, Row, Tab, Tabs } from "react-bootstrap";
import { AppDocumentsSection, AppEvaluation, AppIntersection, EvaluationAppDetails, EvaluationAppMainInfo, RequestHistoryItem } from "../../resources/application";

import 'antd/dist/antd.min.css';
import 'ol/ol.css';
import '../application/react-geo.css';
import '../application/Application.scss';
import { Await, useAsyncError, useLoaderData, useNavigate} from "react-router-dom";
import KTSpinner from "../../components/Thematic/KTSpinner";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../ErrorContext";
import '../application/timeline.css';
import { AppMainData } from "../application/AppMainData";
import { AppData } from "../application/AppData";
import { AppDocs } from "../application/AppDocs";
import { AppIntersections } from "../application/AppIntersections";
import { AppInstitutionsAssessment } from "../application/AppInstitutionsAssessment";
import { AppIssued } from "../application/AppIssued";
import { AppHistory } from "../application/AppHistory";


export enum ProjectionLikeCode {
    LKS94 = 'EPSG:3346',
    WGS84 = 'EPSG:4326',
    WebMercator = 'EPSG:3857',
}

export const Permit: React.FC = () => {
    const data: any = useLoaderData();
          
    return (
        <React.Suspense fallback={<KTSpinner />}>
            <>
                <Await resolve={data && data.appMainData} errorElement={<ReviewsError />} children={
                    (appMainData: EvaluationAppMainInfo) => (
                        <>
                            <Row className="mt-2">
                                <Col className="col-auto">
                                    <h4>{"Nr. " + appMainData.prsNumeris + " • " + appMainData.sukurimoData}</h4>  
                            
                                </Col>
                                <Col >
                                    <Badge bg={appMainData.prbBusena == "ISSUED" ? "primary" : appMainData.prbBusena == "CANCELLED" ? "danger" : "warning"}>{appMainData.busena}</Badge>
                                </Col>
                                
                            </Row>
                            <AppMainData appMainData={appMainData}></AppMainData>
                        </>
                        
                    )
                } />

                <Tabs id='controlled-tab-example' defaultActiveKey={'appData'} >
                    <Tab eventKey='appData' title={"Prašymas"}>
                        <Await resolve={data && data.appDetails} errorElement={<ReviewsError />} children={
                            (appDetail: EvaluationAppDetails) => (
                                <AppData appDetail={appDetail} />

                            )
                        }
                        />
                    </Tab>
                    <Tab eventKey='appDoc' title={"Dokumentai"}>
                        <Await resolve={data && data.documents} errorElement={<ReviewsError />} children={
                            (documents: AppDocumentsSection[]) => (
                                <AppDocs documents={documents} />
                            )
                        } />
                    </Tab>
                    <Tab eventKey='appIntersections' title={"Sankirtos"}>
                        <Await resolve={data && data.intersections} errorElement={<ReviewsError />} children={
                            (inters: AppIntersection[]) => (
                                <AppIntersections inters={inters} />
                            )
                        }
                        />
                    </Tab>  
                    <Tab eventKey='institutionsAssessment' title={"Institucijų vertinimai"}>
                        <Await resolve={data && data.evaluations} errorElement={<ReviewsError />} children={
                            (evas: AppEvaluation[]) => (
                                <AppInstitutionsAssessment evas={evas} />
                            )
                        }

                        />

                    </Tab>                    
                    
                        <Tab eventKey='issuedDocument' title={"Išduotas dokumentas"}>
                        <Await key={"dds"} resolve={data && data.appMainData} errorElement={<ReviewsError />} children={
                            (appMainData: EvaluationAppMainInfo) => (                                
                                <AppIssued />                               
                            )
                        } />
                         </Tab>
                    <Tab eventKey='appHistory' title={"Prašymo istorija"}>
                        <Await resolve={data && data.appHistory} errorElement={<ReviewsError />} children={
                            (appHistory: RequestHistoryItem[]) => (
                                <AppHistory appHistory={appHistory}/>
                            )
                        } />
                    </Tab>
                </Tabs>
            </>
        </React.Suspense>

    );

}

function ReviewsError() {
    const { setError } = useContext(ErrorContext);
    const error: any = useAsyncError();
    const navigate = useNavigate();
    useEffect(() => {
        setError(error);
        if (error.status === 403) {
            navigate('/', { replace: true });
        }
    }, [error, navigate, setError]);
    return <></>;
}

export const wrapText = (text: string): string => {
    if (text?.length > 16) {
        let i = 16;
        while (i > 0) {
            if (text.charAt(i) === ' ' || text.charAt(i) === '-') {
                const left = text.charAt(i) === '-' ? text.substring(0, i + 1) : text.substring(0, i);
                const right = text.substring(i + 1);
                return left + '\n' + wrapText(right);
            }
            i--;
        }
    }
    return text;
};


