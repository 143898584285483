import React, { useCallback, useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Trans, useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import FormGroupComponent from "./form/FormGroupComponent";
import { LkmpFormField } from "./form/LkmpFormField";
import * as yup from "yup";
import { LkmpFormCheckField } from "./form/LkmpFormCheckField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setValidationSchemaLocale, yupPhone } from "./common/validation";
import { AuthContext } from "./AuthContext";
import "./Profilis.scss";
import { NotificationsContext } from "./NotificationsContext";
import { ProfileDto, updateProfile } from "../resources/profile";

library.add(faSpinner);

interface Props {
    onSubmit?: (profilis: ProfileDto) => void;
    onClose: () => void;
    show: boolean;
    allowCloseBtn?: boolean;
}

export const Profilis: React.FC<Props> = ({ show, onSubmit, onClose, allowCloseBtn = true }) => {
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const { pushNotifications } = useContext(NotificationsContext);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [profile, setProfile] = useState<ProfileDto>();

    const validationSchemaProvider = useCallback(() => {
        setValidationSchemaLocale(t);
        const validationSchema = {
            email: yup.string().required().email(),
            tel: yupPhone(t).required(),
            address: yup
                .string()
                .required()
                .matches(/\b[A-Za-zĄ-Žą-ž]+\b/, t("validation.address"))
                .matches(/\d+/, t("validation.address")),
            infoByEmail: yup.boolean(),
            position: yup.string().required(),
        };

        return yup.object(validationSchema);
    }, [t]);

    return (
        <Modal show={show} size="lg" onHide={onClose} backdrop={allowCloseBtn ? true : "static"} keyboard={false}>
            <Modal.Header>
                <Modal.Title>
                    <Trans i18nKey="profilis.header" />
                </Modal.Title>
                {allowCloseBtn && (
                    <button id="modal-close" type="button" className="close" onClick={onClose}>
                        <span>×</span>
                    </button>
                )}
            </Modal.Header>
            <Formik
                validationSchema={validationSchemaProvider}
                initialValues={{
                    address: auth.organization?.contactInfo?.address,
                    email: auth.organization?.contactInfo?.email,
                    infoByEmail: false,
                    name: auth.organization?.contactInfo?.memberFirstName,
                    orgCode: auth.organization?.organizationCode,
                    orgName: auth.organization?.organizationName,
                    position: auth.organization?.contactInfo?.position ?? "",
                    surname: auth.organization?.contactInfo?.memberLastName,
                    tel: auth.organization?.contactInfo?.phone,
                } as ProfileDto}
                enableReinitialize={false}
                onSubmit={async (values) => {
                    setError(false);
                    setSaving(true);                    
                    try {
                        const profilis = await updateProfile(values).then(p => {return p});                             
                        auth!.organization!.contactInfo!.address = profilis.address || "";
                        auth!.organization!.contactInfo!.email = profilis.email || "";
                        auth!.organization!.contactInfo!.memberFirstName = profilis.name || "";
                        auth!.organization!.contactInfo!.memberLastName = profilis.surname || "";
                        auth!.organization!.contactInfo!.position = profilis.position || "";
                       
                        if (auth.updateAuthState) auth.updateAuthState(Object.assign({}, auth));
                    } catch (err) {
                        setError(true);
                    }
                    setSaving(false);
                    onClose();
                    pushNotifications({ title: "profilis.saved" });
                }}
            >
                {({ handleSubmit, isValid, errors }) => {
                    return (
                        <>
                            <Form noValidate onSubmit={() => handleSubmit()} className="profile">
                                <div className="static-data">
                                    <h5 className="mb-5">{t("profilis.atstovaujamasAsmuo")}</h5>
                                    <Form.Label column={false}>{t("profilis.fields.companyName")}</Form.Label>
                                    <div className="h4 mb-4 font-weight-black">{auth.organization?.organizationName}</div>
                                    <Form.Label column={false}>{t("profilis.fields.companyCode")}</Form.Label>
                                    <div className="h4 mb-4 font-weight-black">{auth.organization?.organizationCode}</div>
                                </div>

                                <div className="input-data">
                                    <h5 className="mb-5">
                                        {t("profilis.atstovaujamoAsmensKontaktai")}
                                    </h5>
                                    {(
                                        <>
                                            <FormGroupComponent label="profilis.fields.firstName">
                                                <LkmpFormField name="name" >
                                                    <Form.Control type="text" readOnly/>
                                                </LkmpFormField>
                                            </FormGroupComponent>
                                            <FormGroupComponent label="profilis.fields.contactLastName">
                                                <LkmpFormField name="surname">
                                                    <Form.Control type="text"  readOnly/>
                                                </LkmpFormField>
                                            </FormGroupComponent>
                                            <FormGroupComponent label="profilis.fields.contactPosition">
                                                <LkmpFormField name="position">
                                                    <Form.Control type="text" />
                                                </LkmpFormField>
                                            </FormGroupComponent>
                                        </>
                                    )}
                                    <FormGroupComponent label="profilis.fields.email">
                                        <LkmpFormField name="email">
                                            <Form.Control type="email" />
                                        </LkmpFormField>
                                    </FormGroupComponent>
                                    <FormGroupComponent label="profilis.fields.phone">
                                        <LkmpFormField name="tel">
                                            <Form.Control type="text" placeholder="+370XXXXXXXX" />
                                        </LkmpFormField>
                                    </FormGroupComponent>
                                    <FormGroupComponent label="profilis.fields.address">
                                        <LkmpFormField name="address">
                                            <Form.Control type="text" />
                                        </LkmpFormField>
                                    </FormGroupComponent>
                                    <div className="mb-3">Susisiekimo informacija</div>
                                    <LkmpFormCheckField
                                        name="infoByEmail"
                                        label={t("profilis.fields.infoByEmail")}
                                        id="profile-info-by-email"
                                    />
                                </div>
                            </Form>
                            <Modal.Footer>
                                {allowCloseBtn && (
                                    <Button className="m-1" variant="outline-primary" disabled={saving} onClick={onClose}>
                                        {saving && <FontAwesomeIcon icon="spinner" spin />}
                                        {t("common.button.cancel")}
                                    </Button>
                                )}
                                <Button className="m-1" variant="primary" disabled={saving} type="button" onClick={() => handleSubmit()}>
                                    {saving && <FontAwesomeIcon icon="spinner" spin />}
                                    {t("common.button.save")}
                                </Button>
                            </Modal.Footer>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};
