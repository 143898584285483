import React, { ReactNode } from 'react';
import { IRouteMeta } from '../../permitRoutes';
import HeaderBase from './Partials/Header/HeaderBase';
import ContentBase from './Partials/Content/ContentBase';
import FooterBase from './Partials/Footer/FooterBase';
import { LocaleContext } from '../LocaleContext';
import { Permits } from '../../StrapiService';
interface Props {
  route?: IRouteMeta;
  children?: ReactNode;
}

interface State {
  warning: {
    warningTitle?: string;
    showWarning?: boolean;
  };
}
class PageLayout extends React.Component<Props, State> {
  /*  static contextType = AuthContext
  get authenticated() {
    return this.context.authenticated
  } */
  constructor(props: Props) {
    super(props);
    this.state = {
      warning: {
        warningTitle: '',
        showWarning: false,
      },
    };
  }
  static contextType = LocaleContext;

  resolveTitle() {
    let title = this.props.route?.title;

    this.context === 'en' && this.props.route?.titleEn
      ? (title = this.props.route?.titleEn)
      : (title = this.props.route?.title);

    return title;
  }

  async componentDidMount() {
    // this.setState({
    //   warning: await Permits.getWarningProperties(),
    // });
  }

  render(): ReactNode {
    const title = this.resolveTitle(); //this.props.route?.title
    const icon = this.props.route?.icon;
    const isStrapiPage = this.props.route?.isStrapiPage && true;
    const body = document.body;
    // we remove aside, because on public not authorized we don't need it
    body.classList.remove('kt-aside--enabled');
    return (
      <div
        className={`default-layout${
          this.props.route?.centerContent ? ' centered' : ''
        }`}
      >
        <div className='kt-grid kt-grid--hor kt-grid--root'>
          <div
            className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor'
            id='kt_wrapper'
          >
            <HeaderBase
              children={this.props.children}
              showWarning={this.state.warning.showWarning}
              warningTitle={this.state.warning.warningTitle}
            />
            <ContentBase
              children={this.props.children}
              title={"Page title"}
              icon={""}
              hideWhiteBackground={true}
              pageType= 'strapi-page'
            />
            <FooterBase />
          </div>
        </div>
      </div>
    );
  }
}

export default PageLayout;
