import React, { ReactNode } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Formik, FormikProps } from "formik"

import './filter-form.scss'

interface Props {
  onSubmit: (values?: any, formikHelpers?: any) => void
  buttons: JSX.Element
  filterShort?: (props: FormikProps<any>) => JSX.Element
  filterExtended?: (props: FormikProps<any>) => JSX.Element
  formikInitialValues?: any
  resetExtendedFields?: (props: FormikProps<any>) => void
  buttonsBreakpoint?: 'break-xs' | 'break-llg'
  children?: React.ReactNode
}
interface State {
  toggle: boolean
}

export class KTFilterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      toggle: false,
    }
  }

  toggleExpand(e: React.MouseEvent, props: FormikProps<any>) {
    e.preventDefault()
    this.setState({ toggle: !this.state.toggle })
    if (this.props.resetExtendedFields) {
      this.props.resetExtendedFields(props)
    }
  }

  render(): ReactNode {
    return (
      <div className="filter-form kt-form kt-form--label-right">
        <div className="alis-portlet">
          <Formik initialValues={this.props.formikInitialValues} onSubmit={this.props.onSubmit}>
            {(formikProps) =>
              <div className="alis-portlet__body">
                <Form noValidate onSubmit={formikProps.handleSubmit} className="filter-form kt-form kt-form--label-right">
                  <div className={`filter-block ${ this.props.buttonsBreakpoint ? this.props.buttonsBreakpoint : 'break-xs' }`}>
                    <div className="filter-short">
                      {this.props.filterShort && this.props.filterShort(formikProps)}
                    </div>
                    <div className="button-block">
                      {!this.state.toggle && this.props.buttons}
                    </div>
                  </div>
                  {
                    <div className={`filter-expanded${this.state.toggle ? '' : ' d-none'}`}>
                      {this.props.filterExtended && this.props.filterExtended(formikProps)}
                      <div className="button-block">
                        {this.props.buttons}
                      </div>
                    </div>
                  }
                  {
                    this.props.filterExtended &&
                    <button onClick={(e: React.MouseEvent) => this.toggleExpand(e, formikProps)} className="btn-expand btn btn-link btn-sm btn-block" style={{width:"100%"}}>
                      {
                        this.state.toggle ? 'Sutraukti paiešką' : 'Išplėsti paiešką'
                      }
                      <i className={`la la-arrow-circle-o-${!this.state.toggle ? 'down' : 'up'} pl-1`}></i>
                    </button>
                  }
                </Form>
              </div>
            }
          </Formik>
        </div>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    )
  }
}
