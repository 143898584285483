import React, { PureComponent, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {}
interface State {}

class AsideBrand extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)
  }
  
  minimizeAside() {
    const cList = document.body.classList
    if (
      cList.contains('aside--minimize')
    ) {
      cList.remove('aside--minimize');
      if (cList.contains('kt-aside--minimize')) cList.remove('kt-aside--minimize');
      if (cList.contains('kt-aside--minimize-hover')) cList.remove('kt-aside--minimize-hover');
    } else {
      if (cList.contains('kt-aside--minimize-hover')) cList.remove('kt-aside--minimize-hover');
      cList.add('kt-aside--minimize')
      setTimeout(() => {
        cList.add('aside--minimize')
      }, 1000);
    }
  }

	render(): ReactNode {
		return (
			<div className="kt-aside__brand kt-grid__item" id="kt_aside_brand">
				<div className="kt-aside__brand-logo mt-4">
					<Link to="/" className="d-flex align-items-center">
            <img alt="Logo" src={`${process.env.PUBLIC_URL}/assets/picts/forest_icon_test.svg`} className="ma-0" style={{width:"300 px", height:"400 px"}}/>
				  	{/* <h4 className="text-white font-weight-bold ma-0 pa-0 pl-3">ALIS</h4> */}
					</Link>
				</div>
				{/* <div className="kt-aside__brand-tools">
					<button onClick={() => this.minimizeAside()} className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button>
				</div> */}
			</div>
		)
	}
}

export default AsideBrand
