import { format, parse } from "date-fns";
import { alisDateFormat, alisLocalDateTimeFormat, alisZonedDateTimeFormat, emptySelectOptionValue } from "./types";
import { Classifier } from "./classifiers";

export interface AdresasDto {
    adresasTekstas?: string;
}

export function toDtoFormData<T extends {}>(
    values: T,
    customMappers: Partial<Record<keyof T, (field: keyof T, value: any, formData: FormData) => void>> = {}
): FormData {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
        if (key in customMappers) {
            (customMappers[key as keyof T] as any)(key, value, formData);
        } else {
            defaultFormDataMapper(key, value, formData);
        }
    }
    return formData;
}

function defaultFormDataMapper(key: string, value: any, formData: FormData): void {
    if (value != null) {
        if (Array.isArray(value)) {
            value.forEach((subValue, index) => defaultFormDataMapper(`${key}[${index}]`, subValue, formData));
        } else if (value instanceof Date) {
            formData.set(key, value ? format(value, alisDateFormat) : "");
        } else if (value instanceof File || value instanceof Blob) {
            formData.set(key, value);
        } else if (typeof value === "boolean") {
            formData.set(key, value ? "true" : "false");
        } else if (typeof value === "object") {
            Object.entries(value).forEach(([prop, subValue]) => defaultFormDataMapper(`${key}.${prop}`, subValue, formData));
        } else {
            formData.set(key, String(value));
        }
    }
}

export function toClassifierDto<T extends Classifier>(value: string): T;
export function toClassifierDto<T extends Classifier>(value: string | undefined): T | undefined;
export function toClassifierDto<T extends Classifier>(value?: string): T | undefined {
    return value ? ({ id: Number(value) } as any) : undefined;
}

export function fromClassifierDto(value?: Classifier): string {
    return value && value.id != null ? String(value.id) : emptySelectOptionValue;
}

export function toDateDto(value: Date): string;
export function toDateDto(value: Date | undefined): string | undefined;
export function toDateDto(value?: Date): string | undefined {
    return value ? format(value, alisDateFormat) : undefined;
}

export function fromDateDto(value: string): Date;
export function fromDateDto(value: string | undefined): Date | undefined;
export function fromDateDto(value?: string): Date | undefined {
    return value ? parse(value, alisDateFormat, new Date(0)) : undefined;
}

export function toDateTimeDto(value: Date): string;
export function toDateTimeDto(value: Date | undefined): string | undefined;
export function toDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, "yyyy-MM-dd'T'HH:mm:ss'Z'") : undefined;
}

export function fromZonedDateTimeDto(value: string): Date;
export function fromZonedDateTimeDto(value: string | undefined): Date | undefined;
export function fromZonedDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, alisZonedDateTimeFormat, new Date(0)) : undefined;
}

export function toLocalDateTimeDto(value: Date): string;
export function toLocalDateTimeDto(value: Date | undefined): string | undefined;
export function toLocalDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, alisLocalDateTimeFormat) : undefined;
}

export function fromLocalDateTimeDto(value: string): Date;
export function fromLocalDateTimeDto(value: string | undefined): Date | undefined;
export function fromLocalDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, alisLocalDateTimeFormat, new Date(0)) : undefined;
}

export function toNumberDto(value: string): number;
export function toNumberDto(value: string | undefined): number | undefined;
export function toNumberDto(value?: string): number | undefined {
    return value ? Number(value) : undefined;
}

export function fromNumberDto(value?: number): string {
    return value != null ? String(value) : "";
}

export function toMoneyDto(value: string): number;
export function toMoneyDto(value: string | undefined): number | undefined;
export function toMoneyDto(value?: string): number | undefined {
    return value ? Math.round(Number(value) * 100) : undefined;
}

export function fromMoneyDto(value?: number): string {
    return value != null ? (value / 100).toFixed(2) : "";
}

export function toStringDto(value: string): string;
export function toStringDto(value: string | undefined): string | undefined;
export function toStringDto(value?: string): string | undefined {
    return value ? value : undefined;
}

export function fromStringDto(value?: string): string {
    return value ? value : "";
}

export function fromAdresasDto(value?: AdresasDto): string {
    return value && value.adresasTekstas != null ? value.adresasTekstas : "";
}

export function toKgDto(value: string): string;
export function toKgDto(value: string | undefined): string | undefined;
export function toKgDto(value?: string): string | undefined {
    if (value != null && value !== "") {
        return String(Math.round(Number(value) * 1000));
    } else {
        return undefined;
    }
}

export function fromKgDto(value?: string): string {
    let kg = new String();
    kg = value != null ? (Number(value) / 1000).toFixed(3) : "";
    if (kg.endsWith(".000")) {
        kg = kg.replace(".000", "");
    }
    return String(kg);
}

// interfaces of LKMP internal site 

export interface BrowseFormSearchResult<T> {
    data: T[];
    formActions: FormActions;
    paging: Spr_paging_ot;
    total_cnt: number;
}

export interface FormActions {
    allActions: string[];
    availableActions: string[];
    disabledActions: string[];
    formName: string;
    menuAvailableActions: string[];
}

export interface Spr_paging_ot {
    cnt: number;
    order_clause: string;
    page_size: number;
    skip_rows: number;
    // sum_values: Key_values_ot[];
}

export interface Key_values_ot {
    code: string;
    display_text: string;
    key_value: string;
}

export interface ExtendedSearchParamValue {
    condition: ExtendedSearchCondition;
    value?: string;
    values?: string[];
    upperLower: ExtendedSearchUpperLower;
}
export interface ExtendedSearchParam {
    paramName: string;
    paramValue: ExtendedSearchParamValue;
}

export interface AdvancedSearchParameterStatement {
    paramName: string;
    paramValue: AdvancedSearchParameter;
}

export interface AdvancedSearchParameter {
    condition: string;
    upperLower: string;
    value: string;
    values: string[];
}

export enum ExtendedSearchCondition {
    More = ">",
    Less = "<",
    Equals = "=",
    NotEqual = "!=",
    StartsWith = "%-",
    EndsWith = "-%",
    Contains = "%-%",
    Empty = "null",
    NotEmpty = "notnull"
}
export enum ExtendedSearchUpperLower {
    Regular = "regular",
    Lowercase = "lowercase",
    Uppercase = "uppercase"
}

export interface ApplicationsBrowseRow extends BaseBrowseRow {
    prs_id: number;
    prs_sukurimo_data: string;
    prs_leidimo_tipas: string;
    prs_leidimo_tipas_name: string;
    prs_numeris: string;
    prs_savininkas: string;
    prs_sklypo_kadastrinis_nr: string;
    prs_savivald_kodas: string;
    prs_savivaldybe: string;
    prs_vykdytojas: string;
    prb_busena: string;
    prb_busena_name: string;
    prb_nustatymo_data: string;
    prs_uzduotys: string;
}
export interface PermitBrowseRow extends BaseBrowseRow {
    prs_id: number;
    prs_sukurimo_data: string;
    prs_leidimo_tipas: string;
    prs_leidimo_tipas_name: string;
    prs_numeris: string;
    prs_savininkas: string;
    prs_sklypo_kadastrinis_nr: string;
    prs_savivald_kodas: string;
    prs_savivaldybe: string;
    prs_vykdytojas: string;
    prb_busena: string;
    prb_busena_name: string;
    prb_nustatymo_data: string;
    prs_uzduotys: string;
}

export interface BaseBrowseRow {
    availableActions: ActionsEnum[];
}

  export enum ActionsEnum {
    ACTIONS_READ = 'READ',
    ACTIONS_CHANGE_TABLE_AUDIT = 'CHANGE_TABLE_AUDIT',
    ACTIONS_COPY = 'COPY',
    ACTIONS_CREATE = 'CREATE',
    ACTIONS_DELETE = 'DELETE',
    ACTIONS_UPDATE = 'UPDATE',
    ASSIGN_ORG_ROLE = 'ASSIGN_ORG_ROLE',
    ASSIGN_ROLE = 'ASSIGN_ROLE',
    ASSIGN_USERS = 'ASSIGN_USERS',
    CLEAR_LOCAL_CACHE = 'CLEAR_LOCAL_CACHE',
    GLOBAL_FILTER_MANAGER = 'GLOBAL_FILTER_MANAGER',
    LINK_ACCOUNTS = 'LINK_ACCOUNTS',
    SET_CODES_AND_TRANSLATIONS = 'SET_CODES_AND_TRANSLATIONS',
    SET_FORM_DISABLED_ACTIONS = 'SET_FORM_DISABLED_ACTIONS',
  }
