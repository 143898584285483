import { Card, Col, Form, Row} from "react-bootstrap";
import { EvaluationAppMainInfo, getRequestMapIntersections } from "../../resources/application";
import 'antd/dist/antd.min.css';
import 'ol/ol.css';
import './react-geo.css';
import { MapComponent, SimpleButton } from "@terrestris/react-geo";
import OlMap from 'ol/Map';
import OlView, { AnimationOptions, FitOptions } from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import * as WMTS from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { register } from 'ol/proj/proj4';
import * as proj from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { Feature } from 'ol';
import RenderFeature from 'ol/render/Feature';
import MVT from 'ol/format/MVT';
import { Coordinate } from 'ol/coordinate';
import React, { useContext, useEffect } from "react";
import { getCapabilitiesResponse } from "../../resources/map";
import proj4 from "proj4";
import GeoJSON from 'ol/format/GeoJSON';
import OlSourceOSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Extent } from "ol/extent";
import { ProjectionLikeCode } from "./Application";
import { useParams } from "react-router-dom";

interface Props {
    appMainData: EvaluationAppMainInfo;
}


export const AppMainData: React.FC<Props> = (props) => {
    const { id } = useParams();

    interface MapBackgroundLayer<T extends BaseLayer> {
        key: string;
        layer: T;
        imagePath: string;
    }
    
    interface IntersectionFeatureProperties extends Record<string, unknown> {
        typeCode: string;
        state: string;
        name: string;
        crosses: boolean;
    }


    const backgroundLayers: MapBackgroundLayer<BaseLayer>[] = [
        { key: "base", layer: new TileLayer<WMTS.default>(), imagePath: 'assets/map/bg_base.png' },
        { key: "orto", layer: new TileLayer<WMTS.default>(), imagePath: 'assets/map/bg_orto.png' },
    ];

    const mapView = new OlView({
        maxZoom: 18,
        minZoom: 6,
        enableRotation: false,
        zoom: 7.9,
        extent: [2000000, 7000000, 3150000, 7800000],
    });



    useEffect(() => {





        loadLks();
        loadBackgroundLayersSources();
        loadPlotGeom();

        loadIntersectionsLayer(id || "undefined")
        // console.log("mapView --> ", mapView)
        map.setView(mapView);
        const allLayers: BaseLayer[] = backgroundLayers.map((backgroundLayer, index) => {
            backgroundLayer.layer.setVisible(index === 0);
            return backgroundLayer.layer;
        })

        allLayers.push(intersectionsOlLayer);
        allLayers.push(mvkOlLayer);

        allLayers.push(plotOlLayer);



        map.setLayers(allLayers);
        // mapView.setCenter(
        //   proj.transform([23.8, 55.2], ProjectionLikeCode.WGS84, ProjectionLikeCode.WebMercator)
        // );

    })

    function loadLks(): void {
        proj4.defs(
            "EPSG:3346",
            '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9998+x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m+no_defs'
        );
        register(proj4);
        const lksExtent = [307558.9491752772, 5970000.0, 685218.1236202281, 6264605.2051798785];
        proj.get("EPSG:3346")!.setExtent(lksExtent);
    }



    const map =
        new OlMap({
            layers: [
                new OlLayerTile({
                    source: new OlSourceOSM()
                })
            ],
            view: new OlView({
                center: fromLonLat([37.40570, 8.81566]),
                zoom: 10
            })
        });


    // const map = new OlMap({
    //     controls: defaultControls({
    //         rotate: false,
    //         attribution: false,
    //         zoom: false,
    //     }).extend([
    //         new ScaleLine({
    //             units: 'metric',
    //         }),
    //         new Attribution({
    //             tipLabel: "this.translateService.instant('components.map.view.attributions') as string",
    //         }),
    //     ]),
    //     interactions: defaultInteractions({ mouseWheelZoom: false }).extend([
    //         new MouseWheelZoom({ constrainResolution: true, duration: 10, timeout: 0 }),
    //     ]),
    // });

    function loadBackgroundLayersSources(): void {

        getCapabilitiesResponse("/assets/map/WMTSCapabilities.xml").then(l => {
            const parser = new WMTSCapabilities();
            const parserResult: unknown = parser.read(l);
            const options = WMTS.optionsFromCapabilities(parserResult, {
                layer: 'geoportal_public_background_Lietuva_102100',
                matrix: 'default028mm',
            });
            if (options) {
                options.crossOrigin = '';
                (backgroundLayers[0].layer as OlLayerTile<WMTS.default>).setSource(new WMTS.default(options));
            } else {
                console.log("Kazkas kito ....");

            }

        });

        // getCapabilitiesResponse("https://www.geoportal.lt/arcgis/rest/services/NZT/ORT10LT_Web_Mercator_102100/MapServer/WMTS/1.0.0/WMTSCapabilities.xml").then(l => {
        //     const parser = new WMTSCapabilities();
        //     const parserResult: unknown = parser.read(l);
        //     const options = WMTS.optionsFromCapabilities(parserResult, {
        //         layer: 'NZT_ORT10LT_Web_Mercator_102100',
        //         matrix: 'default028mm',
        //     });
        //     if (options) {
        //         options.crossOrigin = '';
        //         (backgroundLayers[1].layer as OlLayerTile<WMTS.default>).setSource(new WMTS.default(options));
        //     } else {
        //         console.log("Kazkas kito ....");

        //     }

        // });

    }

    const intersectionsOlLayer = new VectorLayer({
        source: new VectorSource(),
        style: (feature, resolution): Style => {
            return getIntersectionsStyle(feature, resolution, []);
        },
    });


    function loadIntersectionsLayer(id: string | number) {
        intersectionsOlLayer.getSource()!.clear();
        getRequestMapIntersections(id).then((result) => {
            result.forEach((intersection) => {
                intersection.objects?.forEach((obj) => {
                    const feature = new GeoJSON().readFeature(obj.geom);
                    feature.setProperties({
                        typeCode: intersection.typeCode,
                        state: intersection.state,
                        name: obj.name,
                        crosses: obj.crosses,
                    } as IntersectionFeatureProperties);
                    intersectionsOlLayer.getSource()!.addFeature(feature);
                });
            });
        });
    }

    const mvkOlLayer = new VectorTileLayer({
        declutter: true,
        visible: true,
        source: new VectorTileSource({
            format: new MVT(),
            url: process.env.REACT_APP_MAP_API_URL + '/mvk/{z}/{x}/{y}.pbf',
        }),
        style: (feature, resolution): Style => {
            return getMvkStyle(feature, resolution);
        },
    });

    const plotOlLayer: VectorLayer<VectorSource> = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
            fill: new Fill({
                color: 'hsla(50, 96%, 89%, 0.7)',
            }),
            stroke: new Stroke({ width: 2, color: `hsla(26, 90%, 37%, 0.8)` }),
        }),
    });

    function loadPlotGeom(): void {
        
        const plotOlLayerSource = plotOlLayer.getSource();
        plotOlLayerSource && plotOlLayerSource.clear();


        if (plotOlLayer && props.appMainData.geom) {
            // const feature = new GeoJSON().readFeature("{\"type\":\"MultiPolygon\",\"crs\":{\"type\":\"name\",\"properties\":{\"name\":\"EPSG:3857\"}},\"coordinates\":[[[[2849788.408265006,7373360.336430539],[2851120.718400177,7372487.443583361],[2850822.097162984,7371442.269253184],[2849466.816163414,7371442.269253184],[2849041.855172023,7372544.870744361],[2849788.408265006,7373360.336430539]]]]}");
            const feature = new GeoJSON().readFeature(props.appMainData.geom);
            plotOlLayerSource && plotOlLayerSource.addFeature(feature);
            const featureGeometry = feature.getGeometry();

            featureGeometry && setBoundingExtent(featureGeometry.getExtent());
        }
    }

    function setBoundingExtent(
        extent: Extent,
        projectionLikeCode: ProjectionLikeCode = ProjectionLikeCode.WebMercator,
        fitOptions?: FitOptions
    ): void {
        fitOptions = fitOptions || { padding: [32, 32, 32, 32], duration: 1000 };
        if (mapView) {
            if (projectionLikeCode !== ProjectionLikeCode.WebMercator) {
                extent = proj.transformExtent(extent, projectionLikeCode, ProjectionLikeCode.WebMercator);

            }
            mapView.fit(extent, fitOptions);
        }
    }

    function setCenterCoordinates(
        coordinates: Coordinate,
        zoom: number = NaN,
        projectionLikeCode: ProjectionLikeCode = ProjectionLikeCode.WebMercator,
        animate: boolean = true
    ): void {
        if (projectionLikeCode !== ProjectionLikeCode.WebMercator) {
            // const projection: proj.Projection = {};
            coordinates = proj.transform(coordinates, projectionLikeCode, ProjectionLikeCode.WebMercator);
        }
        if (animate) {
            const animationOptions: AnimationOptions = {
                center: coordinates,
            };
            if (zoom !== undefined) {
                animationOptions.zoom = zoom;
            }
            mapView.animate(animationOptions);
        } else {
            mapView.setCenter(coordinates);
            if (zoom !== undefined) {
                mapView.setZoom(zoom);
            }
        }
    }

    const mapLayers: any[] = [
        // intersectionsMapLayer,
        {
            key: 'birze',
            name: 'Biržė',
            show: true,
            transparency: 0,
            removable: false,
            layers: [plotOlLayer],
        },
        {
            key: "mvk",
            name: 'Sklypai',
            show: true,
            transparency: 0,
            removable: false,
            layers: [mvkOlLayer],
        },
    ];

    function getIntersectionsStyle(feature: Feature | RenderFeature, resolution: number, selectedOptions: string[]): Style {
        const properties = feature.getProperties() as IntersectionFeatureProperties;
        //   if (!selectedOptions?.length || !selectedOptions.includes(properties.typeCode)) {
        //       return new Style();
        //   }
        type HSL = { h: number; s: number; l: number };
        const hsls: Record<string, HSL> = {
            STVK_V: { h: 206, s: 100, l: 24 },
            STVK_P: { h: 211, s: 95, l: 29 },
            KVR_V: { h: 20, s: 94, l: 55 },
            KVR_P: { h: 25, s: 89, l: 60 },
            BIIS_V: { h: 233, s: 65, l: 20 },
            BIIS_P: { h: 238, s: 60, l: 25 },
        };
        const defaultHsl: HSL = { h: 0, s: 0, l: 50 };
        let strokeWidth = 1;
        if (resolution <= 2) {
            strokeWidth = 3;
        } else if (resolution <= 4) {
            strokeWidth = 2;
        }
        const hsl = hsls[properties.typeCode] || defaultHsl;
        const geometryStroke = new Stroke({ color: properties.crosses ? '#e01' : '#0d1', width: strokeWidth });
        const geometryFill = new Fill({ color: `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${properties.crosses ? 0.7 : 0.3})` });
        const textStroke = new Stroke({ color: '#fffc', width: 4 });
        const textFill = new Fill({ color: `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, 1)` });
        return new Style({
            text: new Text({
                text: properties.name,
                textAlign: 'center',
                textBaseline: 'middle',
                fill: textFill,
                stroke: textStroke,
                font: '11px sans-serif',
                overflow: true,
            }),
            stroke: geometryStroke,
            fill: geometryFill,
        });
    }

    function getMvkStyle(feature: Feature | RenderFeature, resolution: number /*, selectedOptions: string[]*/): Style {
        type HSL = { h: number; s: number; l: number };
        const hsls: Record<string, HSL> = {
            padaliniai: { h: 170, s: 100, l: 35 },
            girininkijos: { h: 150, s: 100, l: 20 },
            sklypai: { h: 89, s: 100, l: 40 },
        };
        const defaultHsl: HSL = { h: 0, s: 0, l: 50 };
        const properties = feature.getProperties();
        const name = properties['pavadinimas'] && `${properties['pavadinimas'] as string}`;
        const layer = properties['layer'] as string;
        const mainLayer = layer.endsWith('_c') ? layer.slice(0, -2) : layer;
        /*if (!selectedOptions?.length || !selectedOptions.includes(mainLayer)) {
          return null;
        }*/
        // TODO: add layer checkbox tree ?
        let strokeWidth = 1;
        if (resolution <= 2) {
            strokeWidth = 3;
        } else if (resolution <= 4) {
            strokeWidth = 2;
        }
        const hsl = hsls[mainLayer] || defaultHsl;
        const strokeColor = `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, 1)`;
        const geometryStroke = new Stroke({ color: strokeColor, width: strokeWidth });
        const textStroke = new Stroke({ color: '#fffc', width: 4 });
        const fill = new Fill({ color: strokeColor });
        const transparentFill = new Fill({ color: '#0000' });
        if (layer.endsWith('_c')) {
            return new Style({
                text: name
                    ? new Text({
                        text: name,
                        textAlign: 'center',
                        textBaseline: 'middle',
                        fill,
                        stroke: textStroke,
                        font: '11px sans-serif',
                        overflow: true,
                    })
                    : undefined,
            });
        } else {
            return new Style({ stroke: geometryStroke, fill: transparentFill });
        }
    }
    



    return (
        <div className="application-document" style={{ marginBottom: "10px" }}>                            
                            <Row className=" header">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <MapComponent
                                                map={map}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card>
                                        <Card.Body>

                                            <Row>
                                                <Col xs="6" sm="6" md="4">
                                                    {"Kadastrinis nr."}
                                                    <strong>{props.appMainData.prsSklypoKadastrinisNr}</strong>
                                                </Col>
                                                <Col xs="6" sm="6" md="4">
                                                    {"Savivaldybė"}
                                                    <strong >{props.appMainData.savivaldybe}</strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <br></br>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        className="not-disabled"
                                                        disabled
                                                        type="checkbox"
                                                        id="ikeistas"
                                                        defaultChecked={props.appMainData.prsIkeista === 'N'}
                                                        label={
                                                            <Form.Label >Turtas <strong>nėra</strong> įkeistas</Form.Label>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        className="not-disabled"
                                                        disabled
                                                        type="checkbox"
                                                        id="uzufruktas"
                                                        label={`Turtui yra nustatyta uzufruktoriaus teisės naudoti svetimą daiktą ir gauti iš jo vaisius, produkciją ir pajamas`}
                                                        defaultChecked={props.appMainData.prsUzufruktorius === 'Y'}
                                                    />

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        className="not-disabled"
                                                        disabled
                                                        type="checkbox"
                                                        id="ikeistas"
                                                        label={`Bendrosios dalinės nuosavybės teise valdomam nekilnojamajam daiktui yra įregistruota naudojimosi tvarka`}
                                                        defaultChecked={props.appMainData.prsBendraNuosavybe === 'Y'}
                                                    />

                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>

                                </Col>
                            </Row>
                        </div>
    );


}