// @ts-nocheck
import React, { Component } from "react";
import "./DragAndDrop.scss";

class DragAndDrop extends Component<{ handleDrop: (files: FileList) => void; className?: string }> {
    state = {
        drag: false,
        dragging: false,
    };
    dragCounter = 0;
    dropRef = React.createRef<HTMLDivElement>();
    handleDrag = (e: React.DragEvent<Element> & { dataTransfer?: any }) => {
        e.preventDefault();
        e.stopPropagation();
    };
    handleDragIn = (e: React.DragEvent<Element> & { dataTransfer?: any }) => {
        this.setState({ dragging: true });
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true });
        }
    };
    handleDragOut = (e: React.DragEvent<Element> & { dataTransfer?: any }) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    };
    handleDrop = (e: React.DragEvent<Element> & { dataTransfer?: any }) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };
    componentDidMount() {
        let div = this.dropRef.current;
        if (div) {
            div.addEventListener("dragenter", this.handleDragIn);
            div.addEventListener("dragleave", this.handleDragOut);
            div.addEventListener("dragover", this.handleDrag);
            div.addEventListener("drop", this.handleDrop);
        }
    }
    componentWillUnmount() {
        let div = this.dropRef.current;
        if (div) {
            div.removeEventListener("dragenter", this.handleDragIn);
            div.removeEventListener("dragleave", this.handleDragOut);
            div.removeEventListener("dragover", this.handleDrag);
            div.removeEventListener("drop", this.handleDrop);
        }
    }
    render() {
        return (
            <div
                className={`drag-and-drop ${this.props.className ? " " + this.props.className : ""}${this.state.dragging ? " dragging" : ""}`}
                ref={this.dropRef}
            >
                Užneškite vieną failą čia arba spauskite "Įkelti"
                {this.props.children}
            </div>
        );
    }
}
export default DragAndDrop;
