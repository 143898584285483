import { toFormData } from "axios";
import { apiGet, apiPost, apiPut } from "./api";
import { LoginResult, WebSessionInfo } from "./auth";
import { ApplicationsBrowseRow, BrowseFormSearchResult, ExtendedSearchParam, PermitBrowseRow, Spr_paging_ot, fromDateDto, fromLocalDateTimeDto, fromStringDto, toDateDto, toDtoFormData, toLocalDateTimeDto, toStringDto } from "./dto";

export interface EvaluationAppMainInfo {
    arPratestas: boolean;
    arVykdytojas: boolean;
    busena: string;
    geom: string;
    neivykdytuSankirtuVertinimu: number;
    prbBusena: string;
    prsBendraNuosavybe: string;
    prsId: number;
    prsIkeista: string;
    prsLeidSukurtas: string;
    prsLeidimoTipas: string;
    prsNumeris: string;
    prsSavivaldKodas: string;
    prsSklypoKadastrinisNr: string;
    prsUzufruktorius: string;
    prsVykUsrId: number;
    savivaldybe: string;
    sukurimoData: string;
    vykdytojas: string;
    vykdytojoPareigos: string;
}

export interface EvaluationAppDetails {
    applicant: RequestOwner;
    area: string;
    block: string;
    cutType: string;
    department: string;
    dominantTrees: string;
    forestGroup: string;
    forestry: string;
    notes: string;
    owners: RequestOwner[];
    plot: string;
    projectExists: boolean;
    prsLeidGaliojimoPabaiga?: Date;
    prsLeidGaliojimoPradzia?: Date;
    prsPratesimoNumeris?: string;
    prsPratesimoPabaiga?: Date;
    prsPratesimoSukurimoData?: Date;
    recoveryMethod: string;
    trees: RequestTree[];
}

export interface RequestOwner {
    address: string;
    applicantType?: string;
    code?: string;
    email: string;
    id?: number;
    isOrganization?: boolean;
    name?: string;
    organizationPerson?: string;
    organizationPersonExtras?: string;
    organizationType?: string;
    phone: string;
}

export interface RequestTree {
    code?: string;
    count: number;
    id?: number;
    name: string;
    volume: number;
}

export interface RequestHistoryItem {
    changedBy: string;
    changedByPosition: string;
    comment: string;
    date: string;
    newExecutor: string;
    newExecutorPosition: string;
    oldExecutor: string;
    oldExecutorPosition: string;
    stateCode: string;
    stateName: string;
    time: string;
}

export interface ApplicationFilterValue {
    submittedFrom?: Date;
    submittedTo?: Date;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: Date;
    stateDateTo?: Date;
    executor?: string;
    applicationType?: string;
}

export interface ApplicationFilterDto {
    submittedFrom?: string;
    submittedTo?: string;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: string;
    stateDateTo?: string;
    executor?: string;
    applicationType?: string;
}

export interface PermitFilterValue {
    submittedFrom?: Date;
    submittedTo?: Date;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: Date;
    stateDateTo?: Date;
    executor?: string;
    applicationType?: string;
}

export interface PermitFilterDto {
    submittedFrom?: string;
    submittedTo?: string;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: string;
    stateDateTo?: string;
    executor?: string;
    applicationType?: string;
}

export interface AppFileDto {
    // id?: number;
    fileUrl?: string;
    tipas?: string;
    pavadinimas?: string;
    fileName?: string;
    file?: File;
    signature?: boolean;
    data?: string;
    pastaba?: string;
}

export interface ParaiskaValues {
    files?: AppFileValues[];
}

export interface AppFileValues {
    // id?: string;
    fileUrl?: string;
    tipas?: string;
    pavadinimas?: string;
    fileName?: string;
    file?: File;
    data?: Date;
    pastaba?: string;
}

function fromAppFileDto(dto: AppFileDto): AppFileValues {
    return {
        // id: dto.id != null ? String(dto.id) : undefined,
        fileUrl: fromStringDto(dto.fileUrl),
        tipas: fromStringDto(dto.tipas),
        pavadinimas: fromStringDto(dto.pavadinimas),
        fileName: fromStringDto(dto.fileName),
        // file should never come from DTO
        // signature : fromStringDto(dto.signature),
        data: fromLocalDateTimeDto(dto.data),
        pastaba: fromStringDto(dto.pastaba),
    };
}

function toParaiskaFileDto(values: AppFileValues): AppFileDto {
    return {
        // id: toNumberDto(values.id),
        fileUrl: toStringDto(values.fileUrl),
        tipas: toStringDto(values.tipas),
        pavadinimas: toStringDto(values.pavadinimas),
        fileName: toStringDto(values.fileName),
        file: values.file,
        data: toLocalDateTimeDto(values.data),
        pastaba: toStringDto(values.pastaba),
    };
}

export function toApplicationFilterDto(value: ApplicationFilterValue): ApplicationFilterDto {
    return {
        submittedFrom: toDateDto(value.submittedFrom),
        submittedTo: toDateDto(value.submittedTo),
        applicationNr: value.applicationNr,
        cadastralNr: value.cadastralNr,
        municipalityId: value.municipalityId,
        ownerApplications: value.ownerApplications
    }
}

export function fromApplicationFilterDto(value: ApplicationFilterDto): ApplicationFilterValue {
    return {
        submittedFrom: fromDateDto(value.submittedFrom),
        submittedTo: fromDateDto(value.submittedTo),
        applicationNr: value.applicationNr,
        cadastralNr: value.cadastralNr,
        municipalityId: value.municipalityId,
        ownerApplications: value.ownerApplications
    }

}

export function getApplicationsList(
    pagingParams: Spr_paging_ot,
    searchParams: Map<string, unknown>,
    extendedParams: ExtendedSearchParam[]
): Promise<BrowseFormSearchResult<ApplicationsBrowseRow>> {
    const paramArray = Array.from(searchParams.entries());
    const myPostBody = { pagingParams, params: paramArray, extendedParams };
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiPost("/lkmp/evaluation/get-evaluation-rec-list", { "userToken": { type: "Bearer", token: session.token } }, myPostBody);
}



export function fetchApplicationData(appId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve("foo");
        }, 300);
    });
}

export function getAssessor(id?: string): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-evaluation-assessor/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function putAssessor(id?: string): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiPut(`/lkmp/evaluation/put-evaluation-assessor/${id}`, { userToken: { type: "Bearer", token: session.token }}, {} );
}


export interface EvaluationData {
    prv_id: string;    
    prv_vertinimas: string;
    prv_pastabos?: string;
    prv_vertintojas?: string;
    usr_person_name?: string;
    usr_person_surname?: string;
    vertintojas?: string;
    files?: AppFileValues[];
    action?: string;

}

export interface Data<T> {
    "data":T[];
}

export interface AppEvaluation {
    assessorEmail: string;
    assessorName: string;
    assessorPhone: string;
    assessorPosition: string;
    creationDate: string;
    creationTime: string;
    documents: AppDocument[];
    evaluation: string;
    evaluator: string;
    notes: string;
    prvId: number;
    psnId: number;
    setDate: string;
    setTime: string;
    typeCode: string;
}

export interface AppIntersection {
    attempts: number;
    comment: string;
    creationDate: string;
    creationTime: string;
    documents: AppDocument[];
    intersectionsCount: number;
    isManual: boolean;
    manual: boolean;
    responseDate: string;
    responseTime: string;
    state: string;
    stateCode: string;
    typeCode: string;
}

export interface AppDocument {
    contentType: string;
    count?: number;
    date: string;
    id: number;
    name: string;
    size: number;
    time: string;
}

export interface AppDocumentsSection {
    documents: AppDocument[];
    order: number;
    section: string;
}

export interface RequestMapIntersection {
    objects: RequestMapIntersectionObject[];
    state: string;
    typeCode: string;
}

export interface RequestMapIntersectionObject {
    crosses: boolean;
    geom: string;
    name: string;
}

export interface SprFile {
    fil_content_type: string;
    fil_key: string;
    fil_name: string;
    fil_size: number;
    fil_status: string;
    fil_status_date: Date;
}

export interface RequestIssuedDoc {
    additionalInfo: string[];
    isdavimoData?: string;
    isdavimoVykdytojas?: string;
    isdavimoVykdytojoPareigos?: string;
    owners: RequestOwner[];
    prbBusena?: string;
    prsId: number;
    prsLeidGaliojimoPabaiga: Date;
    prsLeidGaliojimoPradzia: Date;
    prsLeidGirininkija: number;
    prsLeidMskAtkurimoBudas: string;
    prsLeidMskAtkurimoTerminas: Date;
    prsLeidMskGrupe: string;
    prsLeidMskKertamasPlotas: number;
    prsLeidMskKirtimoRusis: string;
    prsLeidMskKvartaloNumeris: number;
    prsLeidMskSklypoNumeris: string;
    prsLeidMskVyraujantysMedziai: string;
    prsLeidNumeris?: string;
    prsLeidPratestasIki: Date;
    prsLeidTeisinisPagrindas: string;
    prsLeidVmuPadalinys: number;
    prsPratesimoSukurimoData: Date;
    prsSavivaldKodas: string;
    prsSklypoKadastrinisNr: string;
    restrictions: string[];
    savivaldybe: string;
    specialTerms: string;
    trees: RequestTree[];
}

export interface ItemWithChildItems {
    children?: ItemWithChildItems[];
    id: number;
    name: string;
}

export function getEvaluationsDataByAppId(id: string): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-evaluations/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationsDataByAppIdAndState(id: string, state: string ): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-evaluations/${state}/${id}`, { userToken: { type: "Bearer", token: session.token } });
}
export function submitEvaluation(data?: EvaluationData): Promise<EvaluationData> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    const formData = toDtoFormData(data || {})
    return apiPost(`/lkmp/evaluation/submit-evaluation/${data?.prv_id}`, { userToken: { type: "Bearer", token: session.token } },formData);
}
// export function cancelEvaluation(data?: EvaluationData): Promise<EvaluationData> {
//     const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
//     return apiPost(`/lkmp/evaluation/submit-evaluation/${data?.prv_id}`, { userToken: { type: "Bearer", token: session.token } }, {...data, action:"CANCEL"});
// }

export function getEvaluationsDataByAppIdL(id: string): Promise<Data<EvaluationData>> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-evaluation/L/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppMainInfoByAppId(id: string): Promise<EvaluationAppMainInfo> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-main-info/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppDetailsByAppId(id: string): Promise<EvaluationAppDetails> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-details/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppHistoryByAppId(id: string): Promise<RequestHistoryItem[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-history/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppEvaluationsByAppId(id: string): Promise<AppEvaluation[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-evaluations/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppIntersectionsByAppId(id: string): Promise<AppIntersection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-intersections/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppDocumentsByAppId(id: string): Promise<AppDocumentsSection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-documents/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getEvaluationAppDocumentByDocId(id: string): Promise<SprFile> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-document/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getRequestMapIntersections(id: string | number): Promise<RequestMapIntersection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-rec-map-intersections/${id}`, { userToken: { type: "Bearer", token: session.token } });    
}

export function getApplicationIssuedDoc(id: string | number): Promise<RequestIssuedDoc> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-issued-doc/${id}`, { userToken: { type: "Bearer", token: session.token } });    
}

export function getApplicationIssuedDocCls(): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-app-issued-doc-cls`, { userToken: { type: "Bearer", token: session.token }});    
}

export function getPadGir(): Promise<ItemWithChildItems[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/evaluation/get-pad-gir`, { userToken: { type: "Bearer", token: session.token }});    
}