import { SetStateAction, useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TableColumn } from "react-data-table-component/dist/src/DataTable/types";
import { apiPost } from "../../resources/api";
import { OrderDto, PageRespDto} from "../../resources/types";
import { ErrorContext } from "../ErrorContext";
import { PermitFilterValue, getPermitList, toPermitFilterDto } from "../../resources/permit";
import { AdvancedSearchParameterStatement, ExtendedSearchCondition, ExtendedSearchParam, ExtendedSearchUpperLower, Spr_paging_ot, toDateDto } from "../../resources/dto";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import KTSpinner from "../../components/Thematic/KTSpinner";
import { useNavigate } from "react-router-dom";

interface Props {
	filterVal: PermitFilterValue;
	children?: React.ReactNode;
}

export const PermitReactTable: React.FC<Props> = (props:Props) => {

    const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [order, setOrder] = useState<OrderDto>({field:'prs_id', dir: 'ASC'});

	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();

	const fetchData = async (pageNumber: number, order: OrderDto) => {
		setLoading(true);

		const pagingParams: Spr_paging_ot = {
			cnt: NaN,
			order_clause: (order.field + " " + order.dir),
			page_size: 20,
			skip_rows: 0,
			// sum_values: [],
		}

		//   const extendedParm:ExtendedSearchParam[] = [{
		// 	paramName: 'prs_numeris',
		// 	paramValue : {
		// 		condition: ExtendedSearchCondition.Contains,
		// 		value: props.filterVal.applicationNr,
		// 		upperLower: ExtendedSearchUpperLower.Lowercase
		// 	}
		//   }];

		function fromFilterParam(filterParams: PermitFilterValue): AdvancedSearchParameterStatement[] {
			let extSearchArray: AdvancedSearchParameterStatement[] = [];
			if (filterParams.applicationNr) {
				extSearchArray.push(
					{
						paramName: "prs_numeris",
						paramValue: {
							condition: ExtendedSearchCondition.Contains,
							value: filterParams.applicationNr,
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if (filterParams.cadastralNr) {
				extSearchArray.push(
					{
						paramName: "prs_sklypo_kadastrinis_nr",
						paramValue: {
							condition: ExtendedSearchCondition.Contains,
							value: filterParams.cadastralNr,
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if (filterParams.issuedFrom) {
				extSearchArray.push(
					{
						paramName: "prb_nustatymo_data_nuo",
						paramValue: {
							condition: ExtendedSearchCondition.More,
							value: toDateDto(filterParams.issuedFrom),
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if (filterParams.issuedTo) {
				extSearchArray.push(
					{
						paramName: "prb_nustatymo_data_iki",
						paramValue: {
							condition: ExtendedSearchCondition.Less,
							value: toDateDto(filterParams.issuedTo),
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if (filterParams.municipalityId) {
				extSearchArray.push(
					{
						paramName: "prs_savivald_kodas",
						paramValue: {
							condition: ExtendedSearchCondition.Equals,
							value: filterParams.municipalityId,
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if (filterParams.ownerApplications) {
				extSearchArray.push(
					{
						paramName: "mano_vertinimai",
						paramValue: {
							condition: ExtendedSearchCondition.Equals,
							value: "",
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if(filterParams.owner){
				extSearchArray.push(
					{
						paramName: "prs_savininkas",
						paramValue: {
							condition: ExtendedSearchCondition.Contains,
							value: filterParams.owner,
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if(filterParams.applicationState){
				extSearchArray.push(
					{
						paramName: "prb_busena_name",
						paramValue: {
							condition: ExtendedSearchCondition.Equals,
							value: filterParams.applicationState,
							upperLower: ExtendedSearchUpperLower.Regular,
							values: []
						}
					}
				);
			}
	
			if(filterParams.stateDateFrom){
				extSearchArray.push(
					{
						paramName: "prb_nustatymo_data_nuo",
						paramValue: {
							condition: ExtendedSearchCondition.More,
							value:  toDateDto(filterParams.stateDateFrom),
							upperLower: ExtendedSearchUpperLower.Regular,
							values: []
						}
					}
				);
			}
	
			if(filterParams.stateDateTo){
				extSearchArray.push(
					{
						paramName: "prb_nustatymo_data_iki",
						paramValue: {
							condition: ExtendedSearchCondition.Less,
							value:  toDateDto(filterParams.stateDateTo),
							upperLower: ExtendedSearchUpperLower.Regular,
							values: []
						}
					}
				);
			}
	
			if(filterParams.executor){
				extSearchArray.push(
					{
						paramName: "prs_savininkas",
						paramValue: {
							condition: ExtendedSearchCondition.Contains,
							value: filterParams.executor,
							upperLower: ExtendedSearchUpperLower.Lowercase,
							values: []
						}
					}
				);
			}
	
			if(filterParams.applicationType){
				extSearchArray.push(
					{
						paramName: "prs_leidimo_tipas_name",
						paramValue: {
							condition: ExtendedSearchCondition.Equals,
							value: filterParams.applicationType,
							upperLower: ExtendedSearchUpperLower.Regular,
							values: []
						}
					}
				);
			}
			return extSearchArray;
		}



		getPermitList(pagingParams, new Map(), fromFilterParam(props.filterVal) as ExtendedSearchParam[])
			.then((value) => {
				setData(value.data);
				setTotalRows(value.total_cnt);
				setPageNumber(pageNumber);  
			})
			.catch((error) => {
				console.log("Kazkas negerai !!!!", error);
			}).finally(() => setLoading(false));



		// const filtering = {...toApplicationFilterDto(props.filterVal), pageNumber:pageNumber, pageSize:pageSize, sort: order};
		// console.log(filtering);

		// apiPost("/application/filter",{}, filtering)
		// .then((res:PageRespDto) => {
		// 	setData(res.data);
		// 	setTotalRows(res.total);
		// 	setPageNumber(pageNumber);
		// })
		// .catch(setError)
		// .finally(() => setLoading(false))

	};

	const handlePageChange = (page: number) => {
		fetchData(page, order);
	};

	const handlePerRowsChange = async (newPageSize: SetStateAction<number>, pageNumber: any) => {
		// setLoading(true);
		// ;
		// apiPost("/permit/filter",{},{...toPermitFilterDto(props.filterVal), pageNumber:pageNumber, pageSize:newPageSize, sort: order})
		// .then((res:PageRespDto) => {
		// 	setData(res.data);
		// 	setPageSize(newPageSize);
		// 	setPageNumber(pageNumber);
		// })
		// .catch(setError)
		// .finally(() => setLoading(false))
	};

	useEffect(() => {
		
		fetchData(pageNumber, order); // fetch page 1 of users
		
	}, [props.filterVal]);
	
	const handleSort = async (column: TableColumn<never>, sortDirection: string) => {		
		setOrder({ field: column.id && column.id.toString() || 'id' , dir: sortDirection.toUpperCase() })
		fetchData(pageNumber, { field: column.id && column.id.toString() || 'id' , dir: sortDirection.toUpperCase() });
	};

	const handleDoubleClick = (row: { prs_id: any; }, event: any) => {
		navigate(`/permit/${row.prs_id}`);
	}

	const columns: TableColumn<never>[] = [
		{
			id: 'prs_id',
			name: 'Id',
			selector: (row: any) => row.prs_id,
			sortable: true,
		},
		{
			id: 'prs_numeris',
			name: 'Prašymo numeris',
			selector: (row: any) => row.prs_numeris,
			sortable: true,
		},
		{
			id: 'prs_savininkas',
			name: 'Miško savininkas (-ai)',
			selector: (row: any) => row.prs_savininkas,
			sortable: true,
		},
		{
			id: 'prs_sklypo_kadastrinis_nr',
			name: 'Kadastrinis Nr.',
			selector: (row: any) => row.prs_sklypo_kadastrinis_nr,
			sortable: true,
		},
		{
			id: 'prs_savivaldybe',
			name: 'Savivaldybė',
			selector: (row: any) => row.prs_savivaldybe,
			sortable: true,
		},
		{
			id: 'prs_vykdytojas',
			name: 'Vertintojas',
			selector: (row: any) => row.prs_vykdytoja,
			sortable: true,
		},
		{
			id: 'prb_busena_name',
			name: 'Būsena',
			selector: (row: any) => row.prb_busena_name,
			sortable: true,
		},
		{
			id: 'prb_nustatymo_data',
			name: 'Būsena data',
			selector: (row: any) => row.prb_nustatymo_data,
			sortable: true,
		},
		{
			cell: (row: any) => (
				<LinkContainer to={`/permit/${row.prs_id}`}>
					<Button type="button" variant="primary" size="sm" title="Peržiūrėti" href="">
						<i className="la la-file mr-0"></i>
					</Button>
				</LinkContainer>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];

	const paginationComponentOptions = {
		rowsPerPageText: 'Eilutės:',
		rangeSeparatorText: 'iš',
		selectAllRowsItem: false,
		selectAllRowsItemText: 'Visos',
	};

	return (
		<DataTable
			columns={columns}
			data={data}
			progressPending={loading}
			progressComponent={(
			<div className="kt-spinner kt-spinner--lg kt-spinner--brand">
			  <span>&nbsp;</span>
			</div>)}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			paginationComponentOptions={paginationComponentOptions}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			noDataComponent={(<><h3>Duomenų nėra</h3></>)}
			onSort={handleSort}
			sortServer
			customStyles={{
				table: {
					style: { minHeight: '500px' }
				},
				rows: {
					highlightOnHoverStyle: {
						backgroundColor: '#dbd9d9',
						
					},
				},
			}}
			onRowClicked={handleDoubleClick}
			highlightOnHover
			pointerOnHover
		/>
	);

}
