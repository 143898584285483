import React from 'react';

export interface ErrorMessage {
	messageKey: string;
	debugInfo: any;
}

export interface ErrorContextValue {
	errorMessage?: ErrorMessage;
	setErrorMessage: (message?: ErrorMessage, redirect?: boolean) => void
	setError: (error: any, redirect?: boolean) => void;
}

export const ErrorContext = React.createContext<ErrorContextValue>({
	setErrorMessage: () => {},
	setError: () => {},
});
