import { Badge, Card, Col, Container, Form, Row } from "react-bootstrap";
import { AppEvaluation } from "../../resources/application";
import { faCalendar, faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileButton } from "../common/FileButton";

interface Props {
    evas: AppEvaluation[];
}


export const AppInstitutionsAssessment: React.FC<Props> = (props) => {
    return (
        <>
            <Row as={Col}>
                {
                    props.evas.map((eva, i) => {
                        return (
                            <Col key={`vert_col_${i}`} sm="12" md="6">
                                <Row>
                                    <Col>
                                        {eva.evaluator === "STVK" && <h4>SAUGOMŲ TERITORIJŲ OBJEKTAS</h4>}
                                        {eva.evaluator === "KVR" && <h4>KULTŪROS PAVELDO OBJEKTAS</h4>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(eva.typeCode === "STVK_V" || eva.typeCode === "KVR_V") && <h5>VERTINIMO METU</h5>}
                                        {(eva.typeCode === "STVK_P" || eva.typeCode === "KVR_P") && <h4>PRATĘSIMO METU</h4>}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <ul key={`eva${i}`} className="timeline">
                                                        <li key={`eva1${i}`}>
                                                            <Row>
                                                                <h5>Išsiųsta užklausa</h5>
                                                            </Row>
                                                            <Row>
                                                                <strong>{eva.creationDate} {eva.creationTime}</strong>
                                                            </Row>
                                                        </li>
                                                        <li key={`eva2${i}`}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <h5>{eva.evaluation + " | " + eva.evaluator}</h5>
                                                                    </Row>
                                                                    {eva.creationDate && eva.creationTime && (
                                                                        <Row>
                                                                            <Form.Label><FontAwesomeIcon icon={faCalendar} /> {eva.creationDate + " " + eva.creationTime}</Form.Label>
                                                                        </Row>
                                                                    )}
                                                                    {eva.assessorName && (
                                                                        <Row>
                                                                            <Form.Label><FontAwesomeIcon icon={faUser} /> {eva.assessorName}</Form.Label>
                                                                        </Row>
                                                                    )}

                                                                    {eva.assessorPhone && (
                                                                        <Row>
                                                                            <Form.Label><FontAwesomeIcon icon={faPhone} /> {eva.assessorPhone}</Form.Label>
                                                                        </Row>
                                                                    )}

                                                                    {eva.assessorEmail && (
                                                                        <Row>
                                                                            <Form.Label><FontAwesomeIcon icon={faEnvelope} /> {eva.assessorEmail}</Form.Label>
                                                                        </Row>
                                                                    )}

                                                                    {eva.notes && (
                                                                        <Card>
                                                                            <Card.Body>
                                                                                {eva.notes}
                                                                            </Card.Body>
                                                                        </Card>

                                                                    )

                                                                    }

                                                                    {eva.documents && eva.documents.map((dok) =>
                                                                    (
                                                                        <Row>
                                                                            <Col><FileButton mime={dok.contentType} fileId={dok.id.toString()} fileName={dok.name} fileSize={dok.size} fileDate={dok.date} /></Col>
                                                                        </Row>
                                                                    )

                                                                    )}
                                                                </Card.Body>
                                                            </Card>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Col>


                        )
                    })
                }
            </Row>
        </>
    );
}