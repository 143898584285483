import { apiGet, textPayloadExtractor, apiPost } from './api';
import { ContactInfo } from './types';
import { Authorization } from '../view/AuthContext';
import { toStringDto, toNumberDto, fromStringDto } from './dto';
import { ViispAuthResult, ViispMockAuthRequest } from '../view/Login/ViispMockAuth';
import { ViispAuthRequest } from '../view/Login/ViispAuth';
import { getProfile } from './profile';


export  async function toAuthorizationViisp(payload: LoginResult<WebSessionInfo>): Promise<Authorization> {
  const auth:Authorization = new Authorization();
    
  await getProfile().then(p => {
    auth.organization = {
      contactInfo: {
        memberFirstName: p.name ,
        memberLastName:p.surname,
        email: p.email,
        phone: p.tel,
        address: p.address ,
        position: p.position
  
      },
      organizationCode: p.orgCode,
      organizationName: p.orgName
    }
    auth.authenticated = true;
    return auth;
  }).catch(() => {
    auth.organization = {
      contactInfo: {
        memberFirstName: payload.session.personName ,
        memberLastName:payload.session.personLastName,
        email: "",
        phone: "",
        address: "",
        position: ""
  
      },
      organizationCode: payload.session.orgName,
      organizationName: payload.session.orgId.toString()
    }
    auth.authenticated = true;
  })
  return auth;

};


export function fromAtstovaujamoAsmensProfilisDto2(
  dto: any
): ContactInfo {
  return {
    address: dto.adresas ? fromStringDto(dto.adresas.adresasTekstas) : '',
    email: fromStringDto(dto.epastas),
    phone: fromStringDto(dto.tel),
    
  };
}

export function readViispTicket(authParam:ViispAuthRequest): Promise<any> {
  return apiPost('/auth/viisp/init-viisp-auth', {},authParam);
}


//Test login
export interface AsmuoValues {
  asmensKodas?: string;
  vardas?: string;
  pavarde?: string;
  viispId?: string;
  id?: string;

  imonesKodas?: string;
  imonesPavadinimas?: string;
}

export interface AsmuoValuesDto {
  asmensKodas?: string;
  vardas?: string;
  pavarde?: string;
  viispId?: string;
  id?: number;

  imonesKodas?: string;
  imonesPavadinimas?: string;
}

export interface BasicAuthReq {
  username: string;
  password: string;
}

export interface LoginResult<T> {
  session: T;
  token: string;
}

export interface WebSessionInfo {
  defaultRoute: string;
  language: string;
  orgId: number;
  orgName: string;
  personLastName: string;
  personName: string;
  role: string;
  roleCode: string;
  roleId: number;
  roleName: string;
  userName: string;
  usrPasswordChangeToken: string;
  usrTermsAccepted: string;
}


// export function postViisp(values: AsmuoValues): Promise<AsmuoValues> {
//   return apiPost('/auth/viisp/dummy-login', {}, toAsmuoDto(values));
// }

export function postBasicAuth(values: BasicAuthReq): Promise<LoginResult<WebSessionInfo>> {
  return apiPost('/auth/login', {}, values);
}

export function postViispMock(values: ViispMockAuthRequest): Promise<ViispAuthResult> {
  return apiPost('/auth/viisp/init-mock-viisp-auth', {}, values);
}

export function postViisp(values: ViispAuthRequest): Promise<ViispAuthResult> {
  return apiPost('/auth/viisp/init-viisp-auth', {}, values);
}

export function createSessionViisp(values: String): Promise<LoginResult<WebSessionInfo> | any> {
  return apiPost('/auth/viisp/create-session-viisp', {}, {payload:values});
}

export function isSessionValid(): Promise<string> {
  const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
  return apiGet('/auth/check-session', {"userToken":{type:"Bearer", token:session.token}}, (resp)=>{return resp.text()});
}

export function logoutSession(): Promise<any> {
  const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
  return apiGet('/auth/logout', {"userToken":{type:"Bearer", token:session.token}}, (resp)=>{return resp.text()});
}
