import { Badge, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import { EvaluationAppDetails, getApplicationIssuedDoc, getApplicationIssuedDocCls} from "../../resources/application";
import {  useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCity } from '@fortawesome/free-solid-svg-icons/faCity';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot';
import { useParams } from "react-router-dom";
import { useEffect } from "react";

interface Props {
    appDetail: EvaluationAppDetails;
}


export const AppData: React.FC<Props> = (props) => {
    return (
        <>
            <Row>
                <Col >
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"PAREIŠKĖJAS"}
                        </Card.Header>
                        <Card.Body>

                            <Row>
                                <Form.Label><FontAwesomeIcon icon={props.appDetail.applicant.isOrganization ? faCity : faUser} /> {props.appDetail.applicant.name + ", " + props.appDetail.applicant.code}</Form.Label>
                            </Row>

                            <Row>
                                <Form.Label><FontAwesomeIcon icon={faPhone} /> {props.appDetail.applicant.phone}</Form.Label>
                            </Row>
                            <Row>
                                <Form.Label><FontAwesomeIcon icon={faEnvelope} /> {props.appDetail.applicant.email}</Form.Label>
                            </Row>
                            <Row>
                                <Form.Label><FontAwesomeIcon icon={faLocationDot} /> {props.appDetail.applicant.address}</Form.Label>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
                <Col >
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"PASTABOS"}
                        </Card.Header>
                        <Card.Body>
                            {props.appDetail.notes}
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"SAVININKAI"} <Badge bg="secondary">{props.appDetail.owners.length}</Badge>
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                {
                                    props.appDetail.owners.map((owner, i) => {
                                        return (
                                            <Col key={`owner_col_${i}`} lg="4">
                                                <Card style={{ marginTop: "10px" }}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={owner.isOrganization ? faCity : faUser} /> {owner.name + ", " + owner.code}</Form.Label>
                                                        </Row>

                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faPhone} /> {owner.phone}</Form.Label>
                                                        </Row>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faEnvelope} /> {owner.email}</Form.Label>
                                                        </Row>
                                                        <Row>
                                                            <Form.Label><FontAwesomeIcon icon={faLocationDot} /> {owner.address}</Form.Label>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            {"KERTAMO MIŠKO DUOMENYS"}
                        </Card.Header>
                        <Card.Body className="application-document">
                            <Row className="body">
                                <Col xs="6" sm="6" md="2">
                                    {"Kvartalas"}
                                    <strong>{props.appDetail.block}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Sklypas"}
                                    <strong >{props.appDetail.plot}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Plotas"}
                                    <strong >{props.appDetail.area}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"VMU Padalinys"}
                                    <strong >{props.appDetail.department}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Girinkija"}
                                    <strong >{props.appDetail.forestry}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" sm="6" md="2">
                                    {"Vyraujantys medžiai"}
                                    <strong>{props.appDetail.dominantTrees}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Miškų grupė"}
                                    <strong >{props.appDetail.forestGroup}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Kirtimo rūšis"}
                                    <strong >{props.appDetail.cutType}</strong>
                                </Col>
                                <Col xs="6" sm="6" md="2">
                                    {"Atkūrimo būdas"}
                                    <strong >{props.appDetail.recoveryMethod}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {"Kertami medžiai"}
                                    <strong >{props.appDetail.trees.reduce((p, c) => p + c.count, 0)} vnt.  •  {props.appDetail.trees.reduce((p, c) => p + c.volume, 0)} m³</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" sm="6" md="3">
                                    <ListGroup style={{ marginLeft: "10px" }}>
                                        {
                                            props.appDetail.trees.map((tree, i) => {
                                                return (
                                                    <ListGroup.Item key={`trees_${i}`} ><strong>{tree.name + " • " + tree.count + " vnt. • " + tree.volume + " m³"}</strong></ListGroup.Item>
                                                );
                                            })
                                        }

                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        className="not-disabled"
                                        disabled
                                        type="checkbox"
                                        id="ikeistas"
                                        defaultChecked={!!!props.appDetail.projectExists}
                                        label={
                                            <Form.Label >Valdai <strong>nėra</strong> parenktas galiojantis vidinės miškotvarkos projektas</Form.Label>
                                        }
                                    />
                                </Col>
                            </Row>

                            {/* <FormGroupComponent label='f357_5_a.diagnosisCaseEstablishedInfo'>
                                            <Form.Control as='textarea' />
                                        </FormGroupComponent> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}