import React, { ReactNode } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

interface Props extends ButtonProps {
  showSpinner?: boolean
  onClick?: any
  disabled?: boolean
  className?: string
}
interface State {}

export class KTButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      
    }
  }

  get classN() {
    const c = this.props.showSpinner ? 'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light' : ''
    return this.props.className ? this.props.className + ' ' + c : c
  }

  render(): ReactNode {
    return (
      <Button variant={this.props.variant ? this.props.variant : 'primary'} type={ this.props.type ? this.props.type : 'button' } disabled={this.props.disabled} className={this.classN} onClick={this.props.onClick}>
        {this.props.showSpinner ? ' ' : this.props.children}
      </Button>
    )
  }
}
