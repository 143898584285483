import React, { PureComponent, ReactNode } from 'react'
import HeaderBase from './Partials/Header/HeaderBase'
import HeaderBaseMobile from './Partials/Header/HeaderBaseMobile'
import AsideBase from "./Partials/Aside/AsideBase"
import FooterBase from "./Partials/Footer/FooterBase";
import ContentBase from './Partials/Content/ContentBase'
import { IRouteMeta } from '../../permitRoutes';
//import { titleByRoute } from '../../router';

interface Props {
  route?: IRouteMeta
  layout?: string
  logout?: () => void
  children?: ReactNode
}

interface State {
  mobileAsideShow: boolean
  mobileToolbarShow: boolean
}

class AdminLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      mobileAsideShow: false,
      mobileToolbarShow: false,
    }
  }

  mobileAsideToggle() {
    this.setState({
      mobileAsideShow: !this.state.mobileAsideShow
    }, () => {
      this.state.mobileAsideShow ?
        document.body.classList.add("kt-aside--on") :
        document.body.classList.remove("kt-aside--on")
    })
  }

  mobileToolbarToggle() {
    this.setState({
      mobileToolbarShow: !this.state.mobileToolbarShow
    }, () => {
      this.state.mobileToolbarShow ?
        document.body.classList.add("kt-header__topbar--mobile-on") :
        document.body.classList.remove("kt-header__topbar--mobile-on")
    })
  }

  get pageTitle() {
    if (this.props.route) {
      return "?????" //titleByRoute(this.props.route) // pageTitle
    }
    return ''
  }

  handleWindowResize() {
    this.setState({
      mobileToolbarShow: false,
      mobileAsideShow: false,
    })
    document.body.classList.remove("kt-aside--on")
    document.body.classList.remove("kt-header__topbar--mobile-on")
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.handleWindowResize());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleWindowResize())
  }
  
  render(): ReactNode {
    const body = document.body
    body.classList.add('kt-aside--enabled')
    return (
      <div className={`default-layout${ this.props.route?.centerContent ? ' centered' : '' }`}>
        <HeaderBaseMobile mobileToolbarToggle={() => this.mobileToolbarToggle()} mobileAsideToggle={() => this.mobileAsideToggle()} />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <AsideBase mobileAsideShow={this.state.mobileAsideShow} mobileAsideToggle={() => this.mobileAsideToggle()} />
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            <HeaderBase children={this.props.children} />
            <ContentBase
              title={this.pageTitle}
              icon={this.props.route?.icon}
              children={this.props.children}
              fluidWidth={true}
              pageType={this.props.route?.pageType}
              hideWhiteBackground={this.props.route?.hideWhiteBackground}
            />
            <FooterBase />
          </div>
        </div>
      </div>
    )
  }
}

export default AdminLayout
