import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { KTButton } from "../../components/Thematic/KTButton";
import { AuthContext } from "../AuthContext";
import { ErrorContext } from "../ErrorContext";
import { LkmpFormDateField } from "../form/LkmpFormDateField";
import { LkmpFormField } from "../form/LkmpFormField";
import "@glideapps/glide-data-grid/dist/index.css";
import { PermitFilterValue } from "../../resources/permit";
import { PermitReactTable } from "./PermitReactTable";
import KTSpinner from "../../components/Thematic/KTSpinner";
import { Await, useAsyncError, useLoaderData, useNavigate } from "react-router-dom";
import { KTFilterForm } from "../../components/Thematic/KTFilterForm/KTFilterForm";
import { Classifier } from "../../resources/classifiers";
import { ApplicationReactTable } from "../application/ApplicationReactTable";

export const Permits: React.FC<{}> = () => {
  const { setError } = useContext(ErrorContext);
  const { t } = useTranslation();
  const { organization } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const data: any = useLoaderData();

  const [filterValues, setFilterValues] = useState<PermitFilterValue>({
    submittedFrom: undefined,
    submittedTo: undefined,
    cadastralNr: '',
    municipalityId: "",
  });

  const handleFilter = useCallback((values: PermitFilterValue) => {
    setFilterValues(values);
  }, [setFilterValues]);


  return (
    <React.Suspense fallback={<KTSpinner />}>
      <Await resolve={data && data.data} errorElement={<ReviewsError />}
        children={(muni) => (
          <>
            <KTFilterForm
              formikInitialValues={filterValues}
              onSubmit={handleFilter}
              buttons={
                <KTButton
                  type='submit'
                  disabled={busy}
                  showSpinner={busy}
                  className='mb-3'

                >
                  {t('common.button.filter')}
                </KTButton>
              }
              buttonsBreakpoint={"break-llg"}
              filterShort={
                ({ handleSubmit, values, handleChange }) => {
                  return (
                    <Row>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Išduota nuo"}</span>
                        <LkmpFormDateField name='issuedFrom' />
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Išduota iki"}</span>
                        <LkmpFormDateField name='issuedTo' />
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Leidimo tipas"}</span>
                        <LkmpFormField name='applicationType'>
                          <Form.Control as='select'>
                            <option value=''>- Pasirinkite -</option>
                            <option value='CUT_NOTIFICATION'>Pranešimas</option>
                            <option value='PERMISSION_TO_CUT'>Leidimas</option>
                          </Form.Control>
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Leidimo numeris"}</span>
                        <LkmpFormField name='applicationNr'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Kadastrinis Nr."}</span>
                        <LkmpFormField name='cadastralNr'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Savivaldybė"}</span>
                        <LkmpFormField name='municipalityId'>
                          <Form.Control as='select'>
                            <option value=''>- Pasirinkite -</option>
                            {(muni as Classifier[]).sort((a, b) => {
                              return a.display.toUpperCase().localeCompare(b.display.toUpperCase())
                            }).map((v: Classifier) => {
                              return (<option id={`muni-${v.key}`} value={v.key}>{v.display}</option>)
                            })
                            }

                          </Form.Control>
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Būsena"}</span>
                        <LkmpFormField name='applicationState'>
                        <Form.Control as='select'>
                          <option value=''>- Pasirinkite -</option>
                          <option value='ISSUED'>Išduotas</option>
                          <option value='CANCELLED'>Anuliuotas</option>
                          <option value='EXPIRED'>Pasibaigęs</option>
                          <option value='EXTENDED'>Pratęstas</option>
                        </Form.Control>
                      </LkmpFormField>
                      </Col>
                    </Row>
                  );
                }
              }
              filterExtended={
                ({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                }) => (
                  <>
                    <Row>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Savininkas"}</span>
                        <LkmpFormField name='owner'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Prašymo Nr."}</span>
                        <LkmpFormField name='applicationNr'>
                          <Form.Control type='text' />
                        </LkmpFormField>
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Būsenos data nuo"}</span>
                        <LkmpFormDateField name='stateDateFrom' />
                      </Col>
                      <Col xs='12' sm='6' md='4' lg='3'>
                        <span>{"Būsenos data iki"}</span>
                        <LkmpFormDateField name='stateDateTo' />
                      </Col>
                    </Row>
                  </>
                )
              }
            >
              <PermitReactTable filterVal={filterValues} />
            </KTFilterForm>
          </>
        )}
      />
    </React.Suspense>
  );

  function ReviewsError() {
    const { setError } = useContext(ErrorContext);
    const error: any = useAsyncError();
    const navigate = useNavigate();
    useEffect(() => {
      setError(error);
      if (error.status === 403) {
        navigate('/', { replace: true });
      }
    }, [error, navigate, setError]);
    return <></>;
  }
};

