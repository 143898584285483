import { toStringDto, fromStringDto, toDateDto, fromDateDto } from "./dto";


export interface Company {
    organizationCode: string;
    organizationName: string;
    contactInfo?: ExtendedContactInfo;
}

export interface ExtendedContactInfo {
    email: string;
    phone: string;
    address: string;
    position: string;    
    memberFirstName: string;
    memberLastName: string;
}

export type AtstovaujamasAsmuo =  Company;

// export class Profile {
//     firstName?: string;
//     lastName?: string;
//     code?: string;
//     dateOfBirth?: string;
//     name?: string;
//     contactInfo?: ExtendedContactInfo;
// }


export interface TableParamValues {
    page: number;
    pageSize: number;
    action: string;
    sortField: string;
    sortOrder: string;
}

export interface PageResultDto {
    total: number;
    pageSize: number;
    pageNumber: number;
    data: any;
}

export interface PageResultDto {
    total: number;
    pageSize: number;
    pageNumber: number;
    data: any;
}

export interface CommonTableValues {
    tableParams?: any;
    tableData?: any;
    handleTableEvent: any;
}

export interface ContactInfo {
    email: string;
    phone: string;
    address: string;
}

// export interface CompanyContactInfo extends ContactInfo {
//     contact: Pick<Person, "firstName" | "lastName">;
//     position: string;
// }

// export function isCompanyContactInfo(contactInfo?: Partial<ContactInfo> | null): contactInfo is CompanyContactInfo {
//     return contactInfo ? "contact" in contactInfo : false;
// }

// export interface AlisUserProfile {
//     contactInfo: ContactInfo;
//     atstovaujamoAsmensContactInfo: ContactInfo | CompanyContactInfo;
// }

export const alisDateFormat = "yyyy-MM-dd";
export const alisZonedDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const alisLocalDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const emptySelectOptionValue = "";

// JAR
export interface LegalEntity {
    imonesKodas: string;
    pavadinimas: string;
}
// GRT
export interface Individual {
    asmensKodas: string | undefined;
    vardas: string;
    pavarde: string;
}

export interface JvsCompany extends LegalEntity {
    buveine: string;
    error?: string; // for error display
    warn?: string; // for error display
}

export interface JvsPerson extends Individual {
    error?: string; // for error display
    warn?: string; // for error display
}

// For L11, L12
export interface SpecialistValues extends JvsPerson {
    pareigos: string;
    dirbaNuoData: Date | undefined;
    dirbaIkiData: Date | undefined;
    imonesKodas: string;
}

export interface SpecialistDto extends Individual {
    pareigos: string;
    dirbaNuoData: string | undefined;
    dirbaIkiData: string | undefined;
    imonesKodas: string;
}

export function toSpecialistDto(values: SpecialistValues): SpecialistDto {
    return {
        asmensKodas: toStringDto(values.asmensKodas),
        vardas: toStringDto(values.vardas),
        pavarde: toStringDto(values.pavarde),

        pareigos: toStringDto(values.pareigos),
        dirbaNuoData: toDateDto(values.dirbaNuoData),
        dirbaIkiData: toDateDto(values.dirbaIkiData),
        imonesKodas: toStringDto(values.imonesKodas),
    };
}

export function fromSpecialistDto(dto: SpecialistDto): SpecialistValues {
    return {
        asmensKodas: fromStringDto(dto.asmensKodas),
        vardas: fromStringDto(dto.vardas),
        pavarde: fromStringDto(dto.pavarde),

        pareigos: fromStringDto(dto.pareigos),
        dirbaNuoData: fromDateDto(dto.dirbaNuoData),
        dirbaIkiData: fromDateDto(dto.dirbaIkiData),
        imonesKodas: fromStringDto(dto.imonesKodas),

        error: "",
        warn: "",
    };
}

export interface L04ResponsiblePerson extends Individual {
    pareigos: string | undefined;
}

export interface TableParamValues {
    page: number;
    pageSize: number;
    action: string;
    sortField: string;
    sortOrder: string;
}

export interface PageReqDto {
    pageNumber: number;
    pageSize: number;
    order: OrderDto;

}

export interface OrderDto {
    field: string;
    dir: Direction | string;
}

type Direction = 'ASC' | 'DESC';

export interface PageRespDto {
    pageNumber: number;
    pageSize: number;
    totalPage: number;
    total: number;
    data: any;
}
