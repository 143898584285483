import './view/i18n';
import { createBrowserRouter, defer } from 'react-router-dom';
import PageWrapper from './view/PageWrapper';
import App from './view/App';
import HomePage from './view/Pages/HomePage';
import HomePageAuthorized from './view/Pages/HomePageAuthorized';
import { Login } from './view/Login';
import { Applications } from './view/application/Applications';
import { Permits } from './view/permit/Permits';
import { Application } from './view/application/Application';
import { getMunicipalitie} from './resources/classifiers';
import { getAssessor, getEvaluationAppDetailsByAppId, getEvaluationAppDocumentsByAppId, getEvaluationAppEvaluationsByAppId, getEvaluationAppHistoryByAppId, getEvaluationAppIntersectionsByAppId, getEvaluationAppMainInfoByAppId, getEvaluationsDataByAppIdL } from './resources/application';
import { Permit } from './view/permit/Permit';
import { getPermitDetailsByAppId, getPermitDocumentsByAppId, getPermitEvaluationsByAppId, getPermitHistoryByAppId, getPermitIntersectionsByAppId, getPermitMainInfoByAppId } from './resources/permit';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/application',
        element: <Applications />,
        index: true, 
        loader: async function () {
          return defer({ data: getMunicipalitie()});
        },
      },
      {
        path: '/application/:id',
        element: <Application />,
        index: true,
        loader: async function ({params}) {      
          return defer(
            { 
              appMainData: getEvaluationAppMainInfoByAppId(params.id || "0"), 
              appDetails: getEvaluationAppDetailsByAppId(params.id || "0"),  
              clsMunicipality: getMunicipalitie(), 
              evaluation:getEvaluationsDataByAppIdL(params.id || "0"),
              evaluations:getEvaluationAppEvaluationsByAppId(params.id || "0"),
              appHistory: getEvaluationAppHistoryByAppId(params.id || "0"),
              intersections: getEvaluationAppIntersectionsByAppId(params.id || "0"),
              documents: getEvaluationAppDocumentsByAppId(params.id || "0")
            });
        }
      },
      {
        path: '/permit',
        element: <Permits />,
        index: true,
        loader: async function () {
          return defer({ data: getMunicipalitie()});
        },
      },
      {
        path: '/permit/:id',
        element: <Permit />,
        index: true,
        loader: async function ({params}) {      
          return defer(
            { 
              appMainData: getPermitMainInfoByAppId(params.id || "0"), 
              appDetails: getPermitDetailsByAppId(params.id || "0"),  
              clsMunicipality: getMunicipalitie(), 
              evaluations:getPermitEvaluationsByAppId(params.id || "0"),
              appHistory: getPermitHistoryByAppId(params.id || "0"),
              intersections: getPermitIntersectionsByAppId(params.id || "0"),
              documents: getPermitDocumentsByAppId(params.id || "0")
            });
        }
      },
      {
        path: '/',
        element: <HomePageAuthorized />,
        index: true,
      },
      
      {
        path: '/login/viisp',
        element: <Login />,
        index: true,
      },
      {
        path: '/login',
        element: <Login />,
        index: true,
      },
    ],
  },
]);
