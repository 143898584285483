import React, { PureComponent, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  locale: string;
  t: TFunction;
}


class HomePageAuthorizedPC extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  
  render(): ReactNode {
    return (
        <div className="position-absolute top-50 start-50">
          <Card>
            <Card.Body>
              <h1 className="text-center">LKMP partnerių portalas</h1>
            </Card.Body>
          </Card>          
        </div>      
    );
  }
}

export const HomePageAuthorized: React.FC<{}> = () => {
  const { t, i18n } = useTranslation();

  return <HomePageAuthorizedPC locale={i18n.language} t={t} />;
};

export default HomePageAuthorized;
