import React, { ReactNode, useContext } from 'react'
// import TopbarSearch from './TopbarSearch'
import TopbarLanguages from './TopbarLanguages'
import TopbarUser from './TopbarUser'
import TopbarLogin from './TopbarLogin'
import TopbarMenu from './TopbarMenu'
import { AuthContext } from "../../../../AuthContext";
import './TopbarBase.scss'
interface Props {
	menu: { title: string, titleEn: string, url: string }[]
	children?: ReactNode
}
class TopbarBase extends React.Component<Props> {
	static contextType:any = AuthContext
	get authenticated() {
		return (this.context as any).authenticated;
	}

	render(): ReactNode {
		return (
			<div className="kt-header__topbar">
				<TopbarMenu menu={this.props.menu} />
				{/* <TopbarSearch /> */}
				<TopbarLanguages />
				{
					this.authenticated ? <TopbarUser /> : <TopbarLogin />
					//this.authenticated ? <h1>Hellloooooo</h1> : <h1>Bay</h1>
				}
			</div>
		)
	}
}

export default TopbarBase
