import React, { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext, Authorization } from '../../../../AuthContext'
import { KeistiAtstovaujamaAsmeni } from '../../../../KeistiAtstovaujamaAsmeni'
import { Profilis } from '../../../../Profilis'
import { log } from 'console'

interface Props {
	context: Authorization;
}
interface State {
	showPersonChangeDialog: boolean
	showProfileDialog: boolean
	allowClose: boolean
}
//const {logout} = useContext(AuthContext);

class DropdownUserSolid extends React.Component<Props, State> {
	static contextType = AuthContext;
	constructor(props: Props) {
		super(props)
		this.state = {
			showPersonChangeDialog: false,
			showProfileDialog: false,
			allowClose: true,
		}
	}
	personChangeDialogToggle(toggle: boolean, e?: React.MouseEvent) {
		this.setState({
			showPersonChangeDialog: toggle,
		})
		e?.preventDefault()
	}
	profileDialogToggle(toggle: boolean, e?: React.MouseEvent) {

		this.setState({
			showProfileDialog: toggle,
			allowClose: true,
		})
		e?.preventDefault()
	}

	userContactNeedsUpdate(authorization: Authorization) {
		if (!!!this.props.context.organization?.contactInfo?.position || !!!this.props.context.organization?.contactInfo?.address || !!!this.props.context.organization?.contactInfo?.email || !!!this.props.context.organization?.contactInfo?.phone) {
			this.setState({
				showProfileDialog: true,
				allowClose: false,
			})
		}
	}

	componentDidMount() {
		this.userContactNeedsUpdate(this.props.context);
	}

	componentWillUnmount() {
		this.setState({});
	}

	render(): ReactNode {
		return (
			<div className="user-solid">
				{/* If more advanced logout menu needed, there is full example in keen themes */}
				<ul className="kt-nav">
					<li className="kt-nav__item">
						<Link onClick={(e) => this.profileDialogToggle(true, e)} to="/" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon2-calendar-3"></i></span>
							<span className="kt-nav__link-text">Mano profilis</span>
						</Link>
					</li>
					<li className="kt-nav__separator kt-nav__separator--fit"></li>
					<li className="kt-nav__custom kt-space-between">
						<Link onClick={(e) => { (this.context as any).logout() }} to="/" className="btn btn-label-brand btn-upper btn-sm btn-bold">
							Atsijungti
						</Link>
					</li>
				</ul>
				{
					this.state.showProfileDialog &&
					<Profilis onClose={() => this.profileDialogToggle(false)} show={true} allowCloseBtn={this.state.allowClose} />
				}
			</div>
		)
	}
}

export default DropdownUserSolid
