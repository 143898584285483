import { apiGet, apiPost } from "./api";
import { AppDocumentsSection, AppEvaluation, AppIntersection, EvaluationAppDetails, EvaluationAppMainInfo, ItemWithChildItems, RequestHistoryItem, RequestIssuedDoc, RequestMapIntersection, SprFile } from "./application";
import { LoginResult, WebSessionInfo } from "./auth";
import { BrowseFormSearchResult, ExtendedSearchParam, PermitBrowseRow, Spr_paging_ot, fromDateDto, toDateDto } from "./dto";


export interface PermitFilterValue {
    submittedFrom?: Date;
    submittedTo?: Date;
    issuedFrom?: Date;
    issuedTo?: Date;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: Date;
    stateDateTo?: Date;
    executor?: string;
    applicationType?: string;
}

export interface PermitFilterDto {
    submittedFrom?: string;
    submittedTo?: string;
    issuedFrom?: string;
    issuedTo?: string;
    applicationNr?: string;
    cadastralNr?: string;
    municipalityId?: string;
    ownerApplications?: boolean;
    owner?: string;
    applicationState?: string;
    stateDateFrom?: string;
    stateDateTo?: string;
    executor?: string;
    applicationType?: string;
}

export function toPermitFilterDto(value: PermitFilterValue): PermitFilterDto {
    return {
        submittedFrom: toDateDto(value.submittedFrom),
        submittedTo: toDateDto(value.submittedTo),
        applicationNr: value.applicationNr,
        cadastralNr: value.cadastralNr,
        municipalityId: value.municipalityId,
        ownerApplications: value.ownerApplications
    }
}

export function fromApplicationFilterDto(value: PermitFilterDto): PermitFilterValue {
    return {
        submittedFrom: fromDateDto(value.submittedFrom),
        submittedTo: fromDateDto(value.submittedTo),
        applicationNr: value.applicationNr,
        cadastralNr: value.cadastralNr,
        municipalityId: value.municipalityId,
        ownerApplications: value.ownerApplications
    }

}

export function getPermitList(
    pagingParams: Spr_paging_ot,
    searchParams: Map<string, unknown>,
    extendedParams: ExtendedSearchParam[]
): Promise<BrowseFormSearchResult<PermitBrowseRow>> {
    const paramArray = Array.from(searchParams.entries());
    const myPostBody = { pagingParams, params: paramArray, extendedParams };
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiPost("/lkmp/evaluation/get-issused-rec-list", { "userToken": { type: "Bearer", token: session.token } }, myPostBody);
}

// export function getEvaluationsDataByAppId(id: string): Promise<any> {
//     const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
//     return apiGet(`/lkmp/evaluation/get-evaluations/${id}`, { userToken: { type: "Bearer", token: session.token } });
// }

// export function getEvaluationsDataByAppIdAndState(id: string, state: string ): Promise<any> {
//     const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
//     return apiGet(`/lkmp/evaluation/get-evaluations/${state}/${id}`, { userToken: { type: "Bearer", token: session.token } });
// }
// export function submitEvaluation(data?: EvaluationData): Promise<EvaluationData> {
//     const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
//     const formData = toDtoFormData(data || {})
//     return apiPost(`/lkmp/evaluation/submit-evaluation/${data?.prv_id}`, { userToken: { type: "Bearer", token: session.token } },formData);
// }

export function getPermitMainInfoByAppId(id: string): Promise<EvaluationAppMainInfo> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-main-info/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitDetailsByAppId(id: string): Promise<EvaluationAppDetails> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-details/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitHistoryByAppId(id: string): Promise<RequestHistoryItem[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-history/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitEvaluationsByAppId(id: string): Promise<AppEvaluation[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-evaluations/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitIntersectionsByAppId(id: string): Promise<AppIntersection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-intersections/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitDocumentsByAppId(id: string): Promise<AppDocumentsSection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-documents/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitDocumentByDocId(id: string): Promise<SprFile> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-document/${id}`, { userToken: { type: "Bearer", token: session.token } });
}

export function getPermitMapIntersections(id: string | number): Promise<RequestMapIntersection[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-rec-map-intersections/${id}`, { userToken: { type: "Bearer", token: session.token } });    
}

export function getApplicationIssuedDoc(id: string | number): Promise<RequestIssuedDoc> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-issued-doc/${id}`, { userToken: { type: "Bearer", token: session.token } });    
}

export function getApplicationIssuedDocCls(): Promise<any> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-app-issued-doc-cls`, { userToken: { type: "Bearer", token: session.token }});    
}

export function getPadGir(): Promise<ItemWithChildItems[]> {
    const session = JSON.parse(localStorage.getItem("session") || "") as LoginResult<WebSessionInfo>;
    return apiGet(`/lkmp/permit/get-pad-gir`, { userToken: { type: "Bearer", token: session.token }});    
}